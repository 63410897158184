import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Input, Button } from "antd";

// util
import Storage from "utils/storage";

// css
import "./search1.less";

// api
import { AddKeyWord } from "api/search";

// img
import search3 from "assets/new_imgs/search3.png";

const Search1 = (props) => {
  const { storeID } = props;
  const history = useHistory();
  const [value, setValue] = useState("");
  useEffect(() => {
    if (history.location.state) {
      setValue(history.location.state.keyword);
    }
  }, [history.location.state]);
  const goSearch = () => {
    if (!value) return;
    if (Storage.getUser()) AddKeyWord({ keyword: value });
    window.open(`/search/?keywords=${value}`);
  };

  const goMerchant = () => {
    history.push(`/merchant/${storeID}`, { keyword: value });
  };

  return (
    <div className="search1">
      <div className="search-input flex">
        <Input
          prefix={
            <img
              src={search3}
              className="ml10 mr2"
              style={{ width: 16 }}
              alt=""
            />
          }
          className="input"
          bordered={false}
          placeholder="请输入商品关键字"
          size="small"
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          onPressEnter={goMerchant}
        />
        <Button className="searchAll" onClick={goMerchant}>
          搜本店
        </Button>
        <Button className="searchOur" onClick={goSearch}>
          搜全站
        </Button>
      </div>
    </div>
  );
};
export default Search1;
