// less
import "./index.less";

// module
import MainBottom from "../../components/MainBottom";

const Module2 = (props) => {
  const { datas } = props;

  const templateFn = (key) => {
    return (datas[key] || { template_common: [] })?.template_common || [];
  };

  return (
    <div className="new_home-module2">
      <div className="module2-top">
        <div className="module2-right">
          {templateFn("right")?.map?.((k, i) => (
            <div
              className="module2-right-item pointer"
              key={i}
              onClick={() => {
                window.open(k.template_url);
              }}
            >
              <img
                src={`${k.template_img}?x-oss-process=image/resize,m_lfit,h_300,w_300`}
                alt={k.template_name}
              />
              <div className="template-info">
                <img
                  className="store-logo"
                  src={k.store_logo}
                  alt={k.template_name}
                />
                <div className="store-name owe textC fw700">{k.store_name}</div>
                <div className="template-name owt">{k.template_name}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="new_home_main_bottom">
        <MainBottom data={templateFn("main_bottom_left")} />
        <MainBottom data={templateFn("main_bottom_right")} />
      </div>
    </div>
  );
};
export default Module2;
