import { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router";
import { Image, message, Popover } from "antd";
import { CopyOutlined, EnvironmentOutlined } from "@ant-design/icons";
import "./header1.less";
import Search1 from "pages/module/search1/search1";
// import Search from "pages/module/search/search";
import Storage from "utils/storage";

import {
  StoreInfo,
  AddFavorites,
  StoreCodeMall,
  DelFavorites,
} from "api/store";

// icon
import icon1 from "assets/new_header/icon1.png";
import icon2 from "assets/new_header/icon2.png";
// import ewm from "assets/new_header/ewm.png";
import store from "assets/new_header/store.png";
import fav from "assets/new_header/fav.png";
import fav_choose from "assets/new_header/fav_choose.png";
import vip_icon3 from "assets/icon/vip_icon3.gif";
import vip_icon4 from "assets/icon/vip_icon4.gif";

const Header1 = (props) => {
  const { store_id, query, data1 } = props;
  const history = useHistory();
  const [data, setData] = useState({});
  const [member, setMember] = useState({});
  const [code, setCode] = useState("");
  const [active, setActive] = useState(0);
  const [active1, setActive1] = useState(0);

  useEffect(() => {
    if (history.location.pathname.indexOf("yiFang") > -1) setActive1(4);
    setMember(() => {
      if (Storage.getUser()) return JSON.parse(Storage.getUser());
      return {};
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useMemo(() => {
    const fetchData = async () => {
      if (store_id !== 0) {
        const res = await StoreInfo({
          store_id: store_id,
        });
        if (res.ResponseStatus === 0) {
          setData(res.datas);
        }
      }
    };
    const storeCodeMall = async () => {
      const res = await StoreCodeMall({
        store_id: store_id,
      });
      if (res.ResponseStatus === 0) {
        setCode(res.datas);
      }
    };
    fetchData();
    storeCodeMall();
  }, [store_id]);
  const addFavorites = async () => {
    const res = await AddFavorites({ store_id: +store_id });
    if (res.ResponseStatus !== 0) return message.error(res.ResponseMsg);
    message.success("收藏成功");
    setData(() => {
      data.follow = 1;
      return { ...data };
    });
  };
  const delFavorites = async () => {
    const res = await DelFavorites({ store_id: +store_id });
    if (res.ResponseStatus === 0) {
      message.success("取消收藏成功");
      setData(() => {
        data.follow = 0;
        return { ...data };
      });
    } else {
      message.error(res.ResponseMsg);
    }
  };
  const copy = (value) => {
    const oInput = document.createElement("input");
    oInput.value = value;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = "oInput";
    oInput.style.display = "none";
    document.body.removeChild(oInput);
    message.success("复制成功");
  };
  return (
    <div className="header1">
      <div className="container flex-center" style={{ height: "100%" }}>
        <div className="flex-center">
          <div
            className="logo"
            onClick={() => {
              history.push("/");
            }}
          >
            {/* <Image
              width={195}
              height={75}
              preview={false}
              src={data1.hong_logo}
            /> */}
            <Image
              height={65}
              preview={false}
              src="https://osss.fangzhifu.com/new_hong.png"
              style={{ objectFit: "contain" }}
              alt="找源头"
            />
          </div>
          <div className="merchand flex-center">
            <div className="flex-center merchand-info">
              <div className="merchand-line" />
              <div>
                <div
                  className="merchand-name pointer"
                  onClick={() => {
                    window.location.href = `/merchant/${data.store_id}`;
                  }}
                >
                  <span>
                    {data.store_name}
                    {data?.is_certified ? (
                      <img
                        title="平台认证商家"
                        className="mr10"
                        src={vip_icon3}
                        style={{ width: 26 }}
                        loop={true}
                        alt=""
                      />
                    ) : null}
                  </span>
                  <span
                    className="merchand-name-copy"
                    onClick={(e) => {
                      e.stopPropagation();
                      copy(data.store_name);
                    }}
                  >
                    <CopyOutlined />
                  </span>
                </div>
                <div className="flex-center">
                  <img
                    className="mr10"
                    src={icon1}
                    style={{ width: 73, height: 19 }}
                    alt=""
                  />
                  <img src={icon2} style={{ width: 20, height: 19 }} alt="" />
                </div>
              </div>
              <div className="merchand-line" />
              <div className="merchand-code mr20">
                <Image
                  preview={false}
                  height={62}
                  width={62}
                  src={code}
                  fallback="https://osss.fangzhifu.com/erp/setting/images.jpeg"
                ></Image>
                <div className="icon">
                  <Image
                    preview={false}
                    height={12}
                    width={12}
                    src={data.store_avatar}
                    fallback="https://osss.fangzhifu.com/erp/setting/images.jpeg"
                  ></Image>
                </div>
                <div className="textC fz12 mtop2">商家微信</div>
              </div>

              {/* <div className="flex-center">
                <div className="textC">
                  <div className="fz14 _999">描述</div>
                  <div className="merchand-source">5.0</div>
                </div>
                <div className="textC" style={{ margin: '0 18px' }}>
                  <div className="fz14 _999">服务</div>
                  <div className="merchand-source">5.0</div>
                </div>
                <div className="textC">
                  <div className="fz14 _999">发货</div>
                  <div className="merchand-source">5.0</div>
                </div>
              </div> */}
              {/* <div className="merchand-open-icon"></div>
              <div className="merchand-line" /> */}

              <div className="specific">
                <div className="specific-header flex-center">
                  <div
                    className="specific-avater pointer"
                    onClick={() => {
                      window.location.href = `/merchant/${data.store_id}`;
                    }}
                  >
                    <img
                      src={data.store_avatar}
                      style={{ width: 48, height: 48, borderRadius: "50%" }}
                      alt=""
                    />
                  </div>
                  <div>
                    <div
                      className="merchand-name pointer"
                      onClick={() => {
                        window.location.href = `/merchant/${data.store_id}`;
                      }}
                    >
                      <span>
                        {data.store_name}
                        {data?.is_certified ? (
                          <img
                            title="平台认证商家"
                            className="mr10"
                            src={vip_icon4}
                            style={{
                              width: 18,
                              display: "inline-block",
                              marginTop: -2,
                            }}
                            loop={true}
                            alt=""
                          />
                        ) : null}
                      </span>
                      <span
                        className="merchand-name-copy pointer _fff"
                        onClick={(e) => {
                          e.stopPropagation();
                          copy(data.store_name);
                        }}
                      >
                        <CopyOutlined />
                      </span>
                    </div>
                    <div className="flex-center">
                      <img
                        className="mr10"
                        src={icon1}
                        style={{ width: 73, height: 19 }}
                        alt=""
                      />
                      <img
                        src={icon2}
                        style={{ width: 20, height: 19 }}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="specific-content">
                  <div className="specific-content-top">
                    <div className="specific-content-title">详细评分</div>
                    <div className="flex-center">
                      <div className="flex-center">
                        <div className="fz12 _666">描述</div>
                        <div className="merchand-source">5.0</div>
                      </div>
                      <div className="specific-line" />
                      <div className="flex-center" style={{ margin: "0 18px" }}>
                        <div className="fz12 _666">服务</div>
                        <div className="merchand-source">5.0</div>
                      </div>
                      <div className="specific-line" />
                      <div className="flex-center">
                        <div className="fz12 _666">发货</div>
                        <div className="merchand-source">5.0</div>
                      </div>
                    </div>
                  </div>
                  <div className="specific-content-conter">
                    <div className="specific-content-title">商家信息</div>
                    <div
                      className="flex-center flex-b"
                      style={{ marginRight: 20 }}
                    >
                      <div>
                        <div className="item">
                          目前有货：{data.goods_count}款
                        </div>
                        <div className="item">最后上新：{data.goods_time}</div>
                        <div className="item">
                          联系电话：{data.store_mobile}
                        </div>
                        <div className="item flex" title={`${data.address}`}>
                          <span>商家地址：</span>
                          <div
                            className="flex1"
                            style={{ lineHeight: "18px", marginTop: "-2px" }}
                          >
                            {/* {data.address} */}
                            <span title={data.address}>
                              {data.address}
                              <span
                                className="merchand-name-copy pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  copy(data.address);
                                }}
                              >
                                <CopyOutlined />
                              </span>
                            </span>
                            <Popover
                              content={
                                <div className="textC" style={{ width: 158 }}>
                                  <img
                                    alt=""
                                    style={{ width: "148px", height: "148px" }}
                                    src={data.store_url}
                                  />
                                  <div className="fz12 textC">
                                    微信扫码，在小程序店铺页面点击
                                    <i className="primary-color">立即前往</i>
                                  </div>
                                </div>
                              }
                            >
                              <span className="ml4 primary-color">
                                <EnvironmentOutlined
                                  style={{ color: "var(--main-color)" }}
                                />
                                <i>查看定位</i>
                              </span>
                            </Popover>
                          </div>
                        </div>
                        <div className="flex-center item">
                          <span>资质认证：</span>
                          <i className="i1" title="已通过身份证认证"></i>
                          <i
                            className="i2 pointer"
                            title="已通过营业执照认证"
                            onClick={() => {
                              window.open(data.yyzz_url);
                            }}
                          ></i>
                        </div>
                        <div className="item">
                          商家官网：{data.store_domain}
                        </div>
                      </div>
                      {/* <div>
                        <div className="shopMegRow">
                          <div className="specific-content-title fz12 _666">
                            商家客服
                          </div>
                          <div className="img">
                            <Image
                              preview={false}
                              height={102}
                              width={102}
                              src={code}
                            ></Image>
                            <div className="icon">
                              <Image
                                preview={false}
                                height={20}
                                width={20}
                                src={data.store_avatar}
                              ></Image>
                            </div>
                          </div>
                          <p>微信扫一扫</p>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="specific-bottom">
                    <div
                      className="button"
                      onClick={() => {
                        window.location.href = `/merchant/${data.store_id}`;
                      }}
                    >
                      <img
                        className="mr10"
                        src={store}
                        style={{ width: 18, height: 17 }}
                        alt=""
                      />
                      <span>进店逛逛</span>
                    </div>
                    {data.follow ? (
                      <div className="button button1" onClick={delFavorites}>
                        <img
                          className="mr10"
                          src={fav_choose}
                          style={{ width: 18, height: 17 }}
                          alt=""
                        />
                        <span>取消店铺</span>
                      </div>
                    ) : (
                      <div className="button button1" onClick={addFavorites}>
                        <img
                          className="mr10"
                          src={fav}
                          style={{ width: 18, height: 17 }}
                          alt=""
                        />
                        <span>收藏店铺</span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="merchand-phone">
              <div>
                <div className="fz14 _999" style={{ marginBottom: 14 }}>手机逛</div>
                <div className="flex-center">
                  <img className="merchand-ewm-icon" src={ewm} style={{ width: 18, height: 18 }} alt='' />
                  <div className="merchand-open-icon"></div>
                </div>
              </div>
              <div className="specific-phone">
                <img src={data.store_url} style={{ width: 130, height: 130 }} alt='' />
                <p>店铺官方微信小程序</p>
              </div>
            </div> */}
          </div>
        </div>
        <Search1
          hot_search={data1.hot_search}
          query={query}
          active={active}
          setActive={setActive}
          active1={active1}
          setActive1={setActive1}
          // setSearchGoods={setSearchGoods}
          // setTotal={setTotal}
          member={member}
          // setImgs={setImgs}
          storeID={store_id}
        ></Search1>
      </div>
    </div>
  );
};
export default Header1;
