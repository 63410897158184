// import { useHistory } from "react-router";
import "./emptygoods.less";
const EmptyGoods = (props) => {
  const { activie } = props;
  // const history = useHistory()
  // const goSearch = () => {
  //   history.push("/search", { keywords: (history.location.state && history.location.state.keywords) || query.keywords });
  // };
  return (
    <div className="emptygoods">
      <div
        className="zhaobudao display_none"
        id="NoData_div"
        style={{ display: "block" }}
      >
        <p className="p1">
          抱歉，没有找到相关{activie ? "店铺" : "产品"}！请重新输入关键词进行搜索{" "}
        </p>
        <p className="fuzzy-p2">
          {/* 我们支持模糊搜索，那么你可以在搜索框中输入其中任意一个文字即可. */}
          {/* 请重新输入关键词进行搜索 */}
        </p>
        {/* <i className="totaSltation pointer" onClick={goSearch}>搜索全站</i> */}
      </div>
    </div>
  );
};
export default EmptyGoods;
