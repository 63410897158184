import axios from "axios";
import md5 from "js-md5";
import "config/global";
import Storage from "utils/storage";

const APPKEY = "Av7iAJ1BMW9vbaDtRaDGXPxNxZP59d6J";

const http = axios.create({
  baseURL: global.baseUrl,
  timeout: "60000",
});

// let qpsMap = new Map()
const qpsController = async (config) => {
  const user = Storage.getUser("member");
  var user_info = JSON.parse(user);
  if (user_info == null) {
    user_info = {};
    user_info.token = "";
  }

  let yy = new Date().getFullYear();
  let mm = new Date().getMonth() + 1;
  let dd = new Date().getDate();
  let hh = new Date().getHours();
  let mf =
    new Date().getMinutes() < 10
      ? "0" + new Date().getMinutes()
      : new Date().getMinutes();
  let ss =
    new Date().getSeconds() < 10
      ? "0" + new Date().getSeconds()
      : new Date().getSeconds();
  let gettime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
  var sign = "";
  if (config.method === "get") {
    config.params.timestamp = gettime;
    config.params.is_pc = 1;
    config.params.token = user_info.token;

    if (config.params.length !== 0) {
      const ordered = {};
      //排序
      Object.keys(config.params)
        .sort()
        .forEach(function (key) {
          if (key !== "sign") {
            ordered[key] = config.params[key];
          }
        });
      //组合
      Object.keys(ordered).forEach((key) => {
        if (ordered[key] !== "") {
          if (ordered[key] instanceof Array) {
            sign += JSON.stringify(ordered[key]);
          } else if (ordered[key] instanceof Object) {
            sign += JSON.stringify(ordered[key]);
          } else if (
            ordered[key] !== "" &&
            ordered[key] !== null &&
            ordered[key] !== undefined
          ) {
            sign += ordered[key];
          }
        }
      });
      sign = md5(sign + APPKEY);
      config.params.sign = sign;
    } else {
      sign = md5(user_info.token + APPKEY);
      config.params = {
        token: user_info.token,
        sign: sign,
      };
    }
  } else if (config.method === "post") {
    config.data.timestamp = gettime;
    config.data.is_pc = 1;
    config.data.token = user_info.token;

    if (config.data) {
      const ordered = {};
      //排序
      Object.keys(config.data)
        .sort()
        .forEach(function (key) {
          if (key !== "sign") {
            ordered[key] = config.data[key];
          }
        });
      //组合
      Object.keys(ordered).forEach((key) => {
        if (ordered[key] instanceof Array) {
          sign += JSON.stringify(ordered[key]);
        } else if (ordered[key] instanceof Object) {
          sign += JSON.stringify(ordered[key]);
        } else if (
          ordered[key] !== "" &&
          ordered[key] !== null &&
          ordered[key] !== undefined
        ) {
          sign += ordered[key];
        }
      });
      sign = md5(sign + APPKEY);
      config.data.sign = sign;
    } else {
      sign = md5(user_info.token + APPKEY);
      config.data = {
        token: user_info.token,
        sign: sign,
      };
    }
  }
  return config;
}
http.interceptors.request.use(qpsController, (error) => {
  console.log(error);
  return Promise.reject();
});

http.interceptors.response.use(
  (response) => {
    if (response.status === 200){
      if (response.data.ResponseStatus === 600) {
        localStorage.removeItem("member");
        window.location.href = "/login";
      } else{
        return response.data;
      } 
    }else {
        Promise.reject();
      }
  },
  (error) => {
    console.log('error', error);
    return Promise.reject();
  }
);

export default http;
