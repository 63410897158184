import { useState,useEffect } from "react";
import { useHistory } from "react-router";
import { Image,  Input, Button  } from "antd";
import "./PrivateHeader.less";

const PrivateHeader = (props) => {
  const { query, setQuery, data1 } = props;
  const history = useHistory()
  const [value, setValue] = useState("");


  useEffect(() => {
    if (history.location.state) {
      setValue(history.location.state.keyword);
      setQuery(() => {
        query.keywords = history.location.state.keyword;
        query.store_id = history.location.pathname.split("/")[2];
        return { ...query };
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const goMerchant = () => {
    if (!value) return setValue(query.keywords);
    if (history.location.pathname === '/') {
      setQuery(() => {
        query.keywords = value;
        return { ...query };
      });
      searchScroll();
    } else {
      history.push(`/`, { keyword: value });
      setTimeout(()=>{
        searchScroll();
      },120)
    }
  };
  const searchScroll = () => {
    const scrollToptimer = setInterval(function () {
      var top = 624;
      var speed = top / 4;
      if (document.body.scrollTop !== 0) {
        document.body.scrollTop += speed;
      } else {
        document.documentElement.scrollTop += speed;
      }
      if (document.body.scrollTop || document.documentElement.scrollTop > 624) {
        clearInterval(scrollToptimer);
      }
      if (
        document.documentElement.scrollTop +
          document.documentElement.clientHeight -
          document.body.scrollHeight <
        1
      ) {
        clearInterval(scrollToptimer);
      }
    }, 30);
  };

  return (
    <div className="PrivateHeader">
      <div className="container flex-center" style={{ height: "100%" }}>
        <div
          className="logo"
          onClick={() => {
            history.push("/");
          }}
          style={{width:145,height:50,overflow:'hidden'}}
        >
          <Image
            width={141}
            height={46}
            style={{objectFit:'contain'}}
            preview={false}
            src={data1.hong_logo}
          />
        </div>
        <div className="search">
          <div className="search-input flex">
            <Input
              className="input"
              bordered={false}
              placeholder="请输入商品关键字"
              size="small"
              value={value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
            <Button className="searchOur" onClick={goMerchant}>
              搜索
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivateHeader;
