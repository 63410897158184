import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  EffectFade,
} from "swiper/core";

// css
import "./index.less";
import "swiper/swiper.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

// module
import MainBottom from "../../components/MainBottom";

SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade]);

const Module4 = (props) => {
  const { datas } = props;
  const [swiper, setSwiper] = useState(2500);
  const [active, setActive] = useState(0);

  const onMouseEnter = (i) => {
    setActive(i);
    swiper.autoplay.stop();
  };
  const onMouseLeave = () => {
    swiper.autoplay.start();
  };
  const onSlideChangeTransitionStart = () => {
    setActive(swiper.realIndex || 0);
  };

  const templateFn = (key) => {
    return (datas[key] || { template_common: [] })?.template_common || [];
  };
  return (
    <div className="new_home-module4">
      <div className="new_home-module4-top flex">
        <div className="spots" onMouseLeave={onMouseLeave}>
          {templateFn("right")?.map?.((k, i) => (
            <div
              className={+active === +i ? "spot spot_active" : "spot"}
              key={i}
              onMouseEnter={() => {
                swiper.slideToLoop(i, 200);
              }}
            >
              <span>{k.store_name}</span>
            </div>
          ))}
        </div>
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          loop
          initialSlide={1}
          autoplay={{ delay: 4000 }}
          effect={"fade"}
          pagination={false}
          navigation={true}
          className="mySwiper"
          onSwiper={setSwiper}
          onSlideChangeTransitionStart={onSlideChangeTransitionStart}
        >
          {templateFn("right")?.map?.((k, i) => (
            <SwiperSlide
              key={i}
              onMouseEnter={() => {
                onMouseEnter(i);
              }}
              onMouseLeave={() => {
                onMouseLeave();
              }}
            >
              <div className="mySwiper-item">
                <div className="box-items">
                  {k.value?.map?.((k1, i1) => (
                    <div
                      className="box-item"
                      key={i * 10 + i1}
                      onClick={() => {
                        window.open(k1?.template_url);
                      }}
                    >
                      <div className="images">
                        <img
                          src={`${
                            k1?.template_img
                          }?x-oss-process=image/resize,m_lfit,h_${
                            i1 > 0 ? 300 : 600
                          },w_${i1 > 0 ? 300 : 600}`}
                          alt={k1?.template_name}
                        />
                      </div>
                      {i1 > 0 ? (
                        <div>
                          <div className="template-name owt">
                            {k1.template_name}
                          </div>
                          <div className="goods-price">
                            <span className="money">¥</span>
                            {k1.goods_price}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ))}
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="new_home_main_bottom">
        <MainBottom data={templateFn("main_bottom_left")} />
        <MainBottom data={templateFn("main_bottom_right")} />
      </div>
    </div>
  );
};
export default Module4;
