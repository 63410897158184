import {useEffect,useState} from 'react'
import "./h5.less";
import { Image } from "antd";
import code from 'assets/mer/code.png'
import Storage from "utils/storage";
import {WxUrl} from 'api/home'
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from "react-router-dom";


const H5 = () => {
  const history = useHistory()
  const [data, setData] = useState({});
  const [url, setUrl] = useState('');

  useEffect(() => {
    if(global.private){
      let href = document.domain.replace('zyterp', 'h5.zyterp')
      // let href = 'wln.h5.zyterp.com'
      if(!href.includes('zyterp')){
        href = href.replace('www', 'h5')
      }
      // console.log(href);
      // history.replace('https://'+href)
      window.location.href = 'http://'+href
    }
    if (Object.keys(data).length === 0) {
      setData(() => {
        if (Storage.getSetting()) return JSON.parse(Storage.getSetting());
        return {};
      });
    }
    
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(()=>{
    if(history?.location?.pathname?.includes?.('merchandise')){
      let arr = history?.location?.pathname?.split?.('/')
      let goods_id = +arr[2]
      // // console.log('goods_id', goods_id);
      WxUrl({ goods_id }).then(res => {
        if(res.ResponseStatus===0){
          setUrl(res.datas)
        }
      })
    }else{
      WxUrl().then(res => {
        if(res.ResponseStatus===0){
          setUrl(res.datas)
        }
      })
    }
  }, [history.location])

  function isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) === 'micromessenger') {
          return true; // 是微信端
      } else {
          return false;
      }
  }
  return (
    <div className="h5">
      <div
        className="open"
        style={{ top: isWeiXin()?'58vh':'' }}
        onClick={() => {
          window.open(url);
        }}
      >
        打开{data.mall_name}
      </div>
      {isWeiXin() ? (
        <div className="code">
          <Image width={100} height={100} src={code} preview={false}></Image>
          <div>长按识别二维码,下载APP</div>
        </div>
      ) : (
        <div
          className="down"
          onClick={() => {
            window.open(
              "https://a.app.qq.com/o/simple.jsp?pkgname=com.fangzhifu.findsource"
            );
          }}
        >
          下载{data.mall_name}
        </div>
      )}

      <div className="pri_name">南通找源头网络科技有限公司</div>
    </div>
  );
};
export default H5;
