import { useState, useEffect } from "react";
import { RightOutlined } from "@ant-design/icons";
import "./Privatebanner.less";

import { storeClass } from "api/store";

import { useHistory } from "react-router";

const Privatebanner = (props) => {
  const { setQuery, query, setActive1, setActive2  } = props
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const [active, setActive] = useState(0);
  const [store_class, setStore_class] = useState([])

useEffect(()=>{
  return storeDate()
},[])
  const storeDate = async () => {
    const res = await storeClass();
    if (res.ResponseStatus === 0) {
      setStore_class([...res.datas]);
    }
  };

  const onMouseEnter = (k, i) => {
    setIsShow(true);
    // if(k?.subs?.length){
    //   setIsShow(true);
    // }else{
    //   setIsShow(false);
    // }
    setActive(i);
  };
  const onMouseLeave = () => {
    setIsShow(false);
  };
  const choose1 = (num) => {
    if(history.location.pathname==='/'){
      setActive1(num);
      setActive2(-1);
      setQuery(() => {
        query.store_class_id = store_class[num].class_id;
        query.page = 1;
        return { ...query };
      });
    }
    let search = "";
    if (history.location.search) {
      let arr = history.location.search.split("?")[1].split("&");
      arr.forEach((k) => {
        if (k.indexOf("i1") > -1) {
          search += "i1=" + num + "&";
        } else if (k.indexOf("i2") > -1) {
          return;
        } else {
          if (k) search += k + "&";
        }
      });
      if (search.indexOf("i1") === -1) search += "i1=" + num + "&";
      search = search.substring(0, search.length - 1);
    } else {
      search = "&i1=" + num;
    }
    if(global.private){
      history.push(`/?${search}`);
      setIsShow(false)
    }
  };
  const choose2 = (num) => {
    if(history.location.pathname==='/'){
      setActive1(active);
      setTimeout(()=>{
        setActive2(num);
      },60)
      setQuery(() => {
        query.store_class_id = store_class[active].subs[num].class_id;
        query.page = 1;
        return { ...query };
      });
    }
    let search = "";
    if (history.location.search) {
      let arr = history.location.search.split("?")[1].split("&");
      arr.forEach((k) => {
        if (k.indexOf("i1") > -1) {
          search += "i1=" + active + "&";
        }else if (k.indexOf("i2") > -1) {
          search += "i2=" + num + "&";
        } else {
          if (k) search += k + "&";
        }
      });
      if (search.indexOf("i1") === -1) search += "i1=" + active + "&";
      if (search.indexOf("i2") === -1) search += "i2=" + num + "&";
      search = search.substring(0, search.length - 1);
    } else {
      search = "&i1=" + active + "&i2=" + num;
    }
    if(global.private){
      history.push(`/?${search}`);
      setIsShow(false)
    }
  };

  return (
    <div
      className="banner1 Privatebanner"
      onMouseEnter={() => {
        setIsShow(true);
      }}
      onMouseLeave={onMouseLeave}
    >
      <ul>
        {store_class?.map?.((k, i) => (
          <li
            key={i}
            className="info"
            onMouseEnter={() => {
              onMouseEnter(k, i);
            }}
          >
            <div className="title flex-b flex-center">
              <div className="flex-center owt2">
                <span
                  className="fz14 _111 fw700 pointer hover-red"
                  onClick={() => {
                    choose1(i);
                  }}
                >
                  {k.class_name}
                </span>
                </div>
                {k?.subs?.length > 0?(
                  <div>
                    <RightOutlined className="fz14 _999" />
                  </div>
                ):null}
            </div>
          </li>
        ))}
      </ul>
      <div
        className="good-expansion"
        onMouseEnter={() => {
          setIsShow(true);
        }}
        style={{
          display: isShow ? "block" : "none",
        }}
      >
        <ul>
          {store_class?.[active]?.subs?.length > 0  &&
          store_class[active].subs.map((item, index) => (
              <li key={index}>
                <div className="title flex-b">
                  <div>
                    <span
                      className="fz14 pointer hover-red"
                      onClick={() => {
                        choose2(index);
                      }}
                    >
                      {item.class_name}
                    </span>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
export default Privatebanner;
