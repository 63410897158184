import { useState, useEffect } from "react";
import { Button } from "antd";
import { QqOutlined } from "@ant-design/icons";

// util
import Storage from "utils/storage";
import { delay } from "utils";

// css
import "./index.less";

// api
import { OrderCount } from "api/merchandise";
// import { SignIn, GetMemberPointsStatus, GetRedpackStatus } from "api/wealth";
// import {
//   WalletPointsMyScore,
//   WalletRedPacketFullChances,
//   WalletRedPacketAcquiredInToday,
// } from "api/java/wealth";
// import { AddEvent } from "api/common";

// img
import order1 from "assets/new_imgs/order1.png";
import order2 from "assets/new_imgs/order2.png";
import order3 from "assets/new_imgs/order3.png";
import no_login from "assets/new_imgs/no_login.png";
// import icon9 from "assets/wealth/wealth_fix_icon9.png";

// const wealth_arr = [
//   {
//     title: "每日签到",
//     count: "sign",
//     all_count: 1,
//   },
//   {
//     title: "浏览商品",
//     count: "browse",
//     all_count: 6,
//     url: "/new_day",
//   },
//   {
//     title: "下载图片",
//     count: "download",
//     all_count: 3,
//     url: "/new_day",
//   },
//   {
//     title: "上传商品",
//     count: "upload",
//     all_count: 4,
//     url: "/new_day",
//   },
//   // {
//   //   title: "下单奖励",
//   //   count: "order",
//   //   all_count: 2,
//   //   url: "/new_day",
//   // },
//   {
//     title: "添加商家微信",
//     count: "add_seller",
//     all_count: 6,
//     url: "/new_day",
//   },
//   {
//     title: "好友助力",
//     count: "assist",
//     all_count: 3,
//     url: "/wealth_invite",
//   },
//   {
//     title: "使用找同款神器",
//     count: "search",
//     all_count: 3,
//     url: "/new_day",
//   },
// ];

const RightAffix = () => {
  // 获取基础配置参数 ---- start ----
  const [data, setData] = useState({});
  const [member, setMember] = useState({});
  useEffect(() => {
    const getInit = () => {
      const set_json = Storage?.getSetting?.();
      if (set_json) {
        const form = JSON.parse(set_json);
        setData(form);
      }
      const json = Storage?.getUser?.();
      if (json) {
        const form = JSON.parse(json);
        setMember(form);
      }
    };
    getInit();
  }, []);
  // 获取基础配置参数 ---- end ----

  // 首页加载智齿
  useEffect(() => {
    (function (w, d, e, x) {
      w[e] = function () {
        w.cbk = w.cbk || [];
        w.cbk.push(arguments);
      };
      x = d.createElement("script");
      x.async = true;
      x.id = "zhichiScript";
      x.className = "zhiCustomBtn"; //该class可自行设置，只需要在使用的自定义入口上同名即可
      x.src =
        "https://zhaoyuantou.soboten.com/chat/frame/v6/entrance.js?sysnum=77e4c0a567f0461cb955e178f7f38e50";
      d.body.appendChild(x);
    })(window, document, "zc");
    window.zc("config", {
      custom: true,
    });
  }, []);

  const goOrder = () => {
    window.open(`/myorderlist?order=10`);
  };
  const goOrder1 = () => {
    window.open(`/myorderlist?order=20`);
  };
  const goRefund = () => {
    window.open(`/returnlist`);
  };

  // 前往购物车
  const goShopcar = async () => {
    window.open("/shopcar");
  };

  // 前去注册登录
  const goLogin = () => {
    window.location.assign("/login");
  };

  // 返回顶部
  const goTop = () => {
    document.body.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  // 客服
  const setInit = () => {
    const uname = member.token
      ? member.member_name
      : "游客" + (window.visitorId || +new Date());
    const tel = member.token ? member.member_mobile : "";
    const face = member.token ? member.member_logo : "";
    setTimeout(() => {
      window.zc("config", {
        groupid: "4",
        custom: true,
        uname: uname,
        tel: tel,
        face: face,
        title: "找源头客服",
      });
    }, 60);
  };

  // 营销活动 ---- start ----

  // const [points, setPoints] = useState({});
  // const [memberPoints, setMemberPoints] = useState({});
  // const memberPointTimer = useRef();
  // 获取我的积分
  // const getPoints = async () => {
  //   const res = await WalletPointsMyScore({});
  //   setPoints(res || {});
  // };
  // 获取积分渠道
  // const getMemberPointsStatus = async () => {
  //   const res = await GetMemberPointsStatus({});
  //   setMemberPoints(res?.datas || {});
  // };

  // 获取今日已抽奖次数
  // const [count, setCount] = useState(3);
  // const walletRedPacketAcquiredInToday = async () => {
  //   const res = await WalletRedPacketAcquiredInToday({});
  //   setCount(res?.total || 0);
  // };
  // window.walletRedPacketAcquiredInToday = walletRedPacketAcquiredInToday;

  // 获取抽奖次数
  // const [fullChances, setFullChances] = useState(0);
  // const getWalletRedPacketFullChances = async () => {
  //   const res = await WalletRedPacketFullChances();
  //   setFullChances(res?.total || 0);
  // };
  // window.getWalletRedPacketFullChances = getWalletRedPacketFullChances;

  // 获取抽奖活动信息
  // const [isStart, setIsStart] = useState(0);
  // const getRedpackStatus = async () => {
  //   const res = await GetRedpackStatus({});
  //   setIsStart(res?.datas?.is_start || 0);
  // };

  // 获取订单数
  const [orderCount, setOrderCount] = useState({});
  const getOrderCount = async () => {
    const res = await OrderCount();
    setOrderCount(res?.datas || {});
  };

  useEffect(() => {
    const getActivityInit = async () => {
      await delay(1000);
      getOrderCount();
      // getRedpackStatus();
      // getPoints();
      // getMemberPointsStatus();
      // getWalletRedPacketFullChances();
      // walletRedPacketAcquiredInToday();
      // window.getMemberPointsStatus = getMemberPointsStatus;
      const member = JSON.parse(Storage.getUser() || "{}");
      if (member?.token) {
        // getMemberPointsStatus();
        // getPoints();
      } else {
        setMember(member);
      }
    };
    if (member?.token) getActivityInit();
  }, [member?.token]);

  // 用户签到
  // const [sigInValve, setSigInValve] = useState(false);
  // const sigInTimer = useRef();
  // const signInBefore = () => {
  //   if (!sigInValve) clearTimeout(sigInTimer.current);
  //   sigInTimer.current = setTimeout(() => {
  //     signIn();
  //   }, 60);
  // };
  // const signIn = async () => {
  //   if (sigInValve) return;
  //   setSigInValve(true);
  //   const res = await SignIn();
  //   if (!res) return;
  //   if (res.ResponseStatus) return message.error(res.ResponseMsg);
  //   message.success("签到成功");
  //   setMemberPoints((form) => {
  //     form.sign = -1;
  //     return { ...form };
  //   });
  //   getMemberPointsStatus();
  // };

  // 前往抽奖
  // const goWealthraffle = () => {
  //   AddEvent({
  //     even_name: "侧边工具栏-活动-去抽奖",
  //   });
  //   if (!isStart) return message.error("活动未开启");
  //   window.location.assign("/wealth_raffle");
  // };
  // 营销活动 ---- end ----

  // 搜图
  // const onClickSearch = () => {
  //   const dom = document.querySelector(".i-search-tag");
  //   if (!dom) {
  //     window.location.assign("/searchassistant");
  //   } else {
  //     window.location.assign("/new_day");
  //   }
  // };

  return (
    <div>
      {/* <div className="wealth-affix" onClick={goWealthraffle}>
        <div className={`wealth-count ${member?.token ? "wealth-count1" : ""}`}>
          {member?.token
            ? wealth_arr?.filter((k) => memberPoints[k.count] !== -1)?.length
            : ""}
        </div>
        {fullChances && 3 - count ? (
          <div className="wealth-raffer-count">
            今日可抽奖{fullChances > 3 - count ? 3 - count : fullChances}次
          </div>
        ) : null}

        <div
          className="wealth-affix-content"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className="content">
            {!member.token ? (
              <div className="wealth-login">
                <i
                  className="primary-color pointer mr8"
                  onClick={() => {
                    window.location.assign("/login");
                  }}
                >
                  登录
                </i>
                参与抽奖
              </div>
            ) : null}

            {wealth_arr?.map?.((k, i) => (
              <div
                className="wealth-item"
                key={i}
                onClick={() => {
                  AddEvent({
                    even_name: `侧边工具栏-活动-${k.title}`,
                  });
                  if (k.count === "search") {
                    onClickSearch();
                  } else {
                    if (!member?.token) {
                      if (i === 0) {
                        window.location.assign("/login");
                      } else {
                        window.location.assign("/login?pathname=" + k.url);
                      }
                    } else {
                      if (i === 0) {
                        if (memberPoints?.sign === -1)
                          return message.error("今日已签到，请明天再来");
                        signInBefore();
                      } else {
                        window.location.assign(k.url);
                      }
                    }
                  }
                }}
              >
                <div
                  className="wealth_icon"
                  style={{
                    backgroundPosition: `0 -${
                      (i === 6 ? 4 : i > 3 ? i + 1 : i) * 27
                    }px`,
                  }}
                ></div>
                <div className="mtop10">
                  <span>{k.title}</span>
                  {member.token ? (
                    <span>
                      {memberPoints[k.count] === -1 ? (
                        <img
                          className="ml4"
                          src={icon9}
                          style={{ width: 10 }}
                          alt=""
                        />
                      ) : (
                        <span className="danger-color">
                          ({memberPoints[k.count] || 0}/{k.all_count})
                        </span>
                      )}
                    </span>
                  ) : null}
                </div>
              </div>
            ))}

            <div
              className="wealth-point pointer"
              onClick={() => {
                AddEvent({ even_name: "侧边工具栏-活动-我的积分" });
                window.location.assign("/wealth");
              }}
            >
              <div className="point-count">
                {member?.token ? points?.score || 0 : "--"}
              </div>
              <div className="point-line" />
              <div className="point-text">我的积分</div>
            </div>

            <div
              className="wealth-btn"
              onClick={() => {
                AddEvent({
                  even_name: "侧边工具栏-活动-去积分中心",
                });
                window.location.assign("/wealth");
              }}
            >
              去积分中心
            </div>
            <div className="wealth-btn" onClick={goWealthraffle}>
              去抽奖
            </div>
          </div>
        </div>
      </div> */}

      <div className="new_home-rightAffix">
        {/* 客服 */}
        <div
          className="item custom"
          onMouseEnter={() => {
            setTimeout(() => {
              window.zc("dynamic_ready");
            }, 60);
          }}
        >
          <div className="custom-icon"></div>
          <div className="title">客服</div>
          <div className="item-box">
            <div className="item-content">
              <div className="kuang">
                <p className="fz12">在线客服</p>
                <div className="mtop10">
                  <Button
                    danger
                    className="fz12 zhiCustomBtn"
                    size="small"
                    onClick={setInit}
                  >
                    点击联系客服
                  </Button>
                </div>
              </div>

              <div className="kuang" style={{ paddingTop: 10 }}>
                <p className="fz12">微信客服</p>
                <div className="code-box mtop10">
                  <img className="code" src={data?.mall_wx} alt="" />
                </div>
              </div>

              <div className="kuang1">
                <div className="title mbottom8 mtop20">在线统一客服QQ</div>
                <div
                  className="btn"
                  onClick={() => {
                    window.open(
                      `https://wpa.qq.com/msgrd?v=3&uin=${data.mall_qq}&site=qq&menu=yes`
                    );
                  }}
                >
                  <QqOutlined />
                  <span>综合咨询</span>
                </div>

                <div className="title">电话客服</div>
                <div className="qqwangwang">
                  综合：{data?.zyt_phone || "400-992-9892"}
                </div>
                <div className="title">服务时间</div>
                <div className="qqwangwang cleBot">
                  <div> 在线客服 </div>
                  <div>9:00-20:00</div>
                  <div className="mtop10"> 电话客服 </div>
                  <div>9:00-18:00</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* 会员 */}
        <div className="item member">
          <div className="custom-icon"></div>
          <div className="title">会员</div>
          <div className="item-box">
            <div className="item-content">
              {member?.token ? (
                <div>
                  <div className="orders">
                    <div className="order" onClick={goOrder}>
                      <img src={order1} wdith={24} height={24} alt="" />
                      <div className="status">
                        <span>待付款</span>
                        <span className="count">{orderCount?.count1 || 0}</span>
                      </div>
                    </div>
                    <div className="order" onClick={goOrder1}>
                      <img src={order2} wdith={24} height={24} alt="" />
                      <div className="status">
                        <span>待发货</span>
                        <span className="count">{orderCount?.count2 || 0}</span>
                      </div>
                    </div>
                    <div className="order" onClick={goRefund}>
                      <img src={order3} wdith={24} height={24} alt="" />
                      <div className="status">
                        <span>售后中</span>
                        <span className="count">{orderCount?.count3 || 0}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className="btn mtop20 pointer"
                    onClick={() => {
                      window.open("/myorderlist");
                    }}
                  >
                    进入会员中心
                  </div>
                </div>
              ) : (
                <div className="kuang">
                  <div className="title fz14">Hi，请登录</div>
                  <div className="orders">
                    <img src={no_login} wdith={90} height={90} alt="" />
                    <div className="msg">您还没有登录，请登录</div>
                  </div>
                  <div className="btn" onClick={goLogin}>
                    登录
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* 购物车 */}
        <div
          className="item buycar"
          onClick={() => {
            goShopcar();
          }}
        >
          <div className="custom-icon"></div>
          <div className="title">购物车</div>
        </div>

        {/* 找源头 */}
        <div className="item code">
          <div className="custom-icon"></div>
          <div className="title">找源头</div>
          <div className="item-box">
            <div className="item-content">
              <div>
                <img className="code" src={data?.mall_wx_logo} alt="" />
                <p className="fz12">官方微信小程序</p>
                <img className="code mtop10" src={data?.mall_gzh_logo} alt="" />
                <p className="fz12">官方微信公众号</p>
                <img className="code mtop10" src={data?.mall_app_logo} alt="" />
                <p className="fz12">官方App</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 返回顶部 */}
      <div className="new_home-backTop" onClick={goTop}>
        <div className="custom-icon"></div>
        <div className="title">TOP</div>
      </div>
    </div>
  );
};
export default RightAffix;
