import { useLayoutEffect, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "./index.less";

import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
SwiperCore.use([Autoplay, Pagination, Navigation]);

const SwiperBanner = (props) => {
  const { data = [] } = props;
  const [swiper, setSwiper] = useState(2500);
  useEffect(() => {
    if (swiper && data?.length > 1) {
      const index = Math.floor(Math.random() * data?.length);
      swiper?.slideToLoop?.(index);
      setTimeout(() => {
        swiper?.autoplay?.start?.();
      }, 200);
    }
  }, [swiper, data.length]);

  const onMouseEnter = () => {
    if (data?.length === 1) return;
    swiper.autoplay.stop();
  };
  const onMouseLeave = () => {
    if (data?.length === 1) return;
    swiper.autoplay.start();
  };

  const [top, setTop] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setTop(document.documentElement.scrollTop);
    }
    window.addEventListener("scroll", updateSize);
    updateSize();
    return () => window.removeEventListener("scroll", updateSize);
  }, []);
  return (
    <div
      className="new_home-fix-right"
      style={{
        position: top > 146 ? "fixed" : "absolute",
        top: top > 146 ? 140 : 290,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Swiper
        slidesPerView={1}
        spaceBetween={1}
        loop={data?.length > 1}
        loopAdditionalSlides={2}
        effect={"fade"}
        loopedSlides={3}
        autoplay={data?.length > 1 ? { delay: 3000 } : false}
        className="mySwiper"
        onSwiper={setSwiper}
      >
        {data?.map?.((k, i) => (
          <SwiperSlide key={i}>
            <div
              className={k?.template_url ? "pointer" : ""}
              style={{
                height: "100%",
                background: `url(${k?.template_img}) no-repeat center / 100%`,
              }}
              onClick={() => {
                if (!k?.template_url) return;
                window.open(k?.template_url);
              }}
            ></div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export default SwiperBanner;
