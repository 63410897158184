import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";

// API
import { GetArticleList } from "api/areicle";

import SwiperCore, { Autoplay } from "swiper/core";
SwiperCore.use([Autoplay]);

const Notice = () => {
  const [data, setArticleList] = useState([]);
  const getArticleList = async (form) => {
    const res = await GetArticleList(form);
    setArticleList(res?.datas || []);
  };

  useEffect(() => {
    getArticleList({ count: 5, class_name: '平台公告' });
  }, []);

  const [swiper, setSwiper] = useState(2500);
  useEffect(() => {
    if (swiper && data?.length > 1) {
      const index = Math.floor(Math.random() * data?.length);
      swiper?.slideToLoop?.(index);
      setTimeout(() => {
        swiper?.autoplay?.start?.();
      }, 200);
    }
  }, [swiper, data.length]);
  const onMouseEnter = () => {
    if (data?.length === 1) return;
    swiper.autoplay.stop();
  };
  const onMouseLeave = () => {
    if (data?.length === 1) return;
    swiper.autoplay.start();
  };

  return (
    <div>
      <div
        className="notice"
        style={{ height: 36 }}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <Swiper
          direction="vertical"
          slidesPerView={1}
          spaceBetween={1}
          loop={data?.length > 1}
          loopAdditionalSlides={2}
          loopedSlides={3}
          autoplay={{ delay: 3000 }}
          onSwiper={setSwiper}
        >
          {data?.map?.((k, i) => (
            <SwiperSlide key={i} style={{ height: 36 }}>
              <div
                className="owt pointer"
                style={{ height: 36, width: 120, marginTop: 1 }}
                onClick={() => {
                  window.open(`/article?id=${k.id}`);
                }}
              >
                {k.title}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
export default Notice;
