import { useEffect, useState } from "react";
import { Divider } from "antd";
import { minBy, maxBy } from "lodash";
import "./index.less";

const Content = ({ details, color_id }) => {
  const [spec_id, setSpec_id] = useState(0);

  // 规格
  const [styleMap, setStyleMap] = useState(new Map());
  const [sizeMap, setSizeMap] = useState(new Map());

  const [styleArr, setStyleArr] = useState([]);
  const [sizeArr, setSizeArr] = useState([]);

  useEffect(() => {
    // 获取sku数据
    const getSkuData = (map, key) => {
      const specList = details?.goods_common_info?.spec_list || {};
      const arr = specList?.[key] || [];
      const new_arr = arr?.filter?.((k) => {
        const arr = map?.get(k.list?.spec_id);
        return arr?.some?.((k) => k.goods_state);
      });
      return new_arr || [];
    };

    // 解析sku数据
    if (details?.spec_list_new?.length) {
      // console.log("数据获取完成", +new Date() - window.first_time);

      const style_map = new Map();
      const size_map = new Map();
      details?.spec_list_new?.forEach?.((k) => {
        if (color_id && k?.spec_value?.color_id !== color_id) return;

        // 款式
        const styleKey = k.spec_value?.goods_spec?.[3]?.spec_id;
        const style_arr = style_map.get(styleKey) || [];
        style_arr.push(k.spec_value);
        style_map.set(styleKey, style_arr);

        // 尺码
        const sizeKey = k.spec_value?.goods_spec?.[1]?.spec_id || "0";
        const size_arr = size_map.get(sizeKey) || [];
        size_arr.push(k.spec_value);
        size_map.set(sizeKey, size_arr);
      });
      setStyleMap(style_map);
      setSizeMap(size_map);

      const style_arr = getSkuData(style_map, 3);
      setStyleArr(style_arr);
      setSizeArr(
        details?.goods_common_info?.spec_list?.[1] || [
          { spec_name: "通用", list: { spec_id: "0" } },
        ]
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details?.goods_common_info?.spec_list, details?.spec_list_new]);

  // 查看是否下架
  const lookState = (map, spec_id) => {
    return !map?.get(spec_id)?.some?.((k) => +k.goods_state);
  };
  // 查看是否缺货
  const lookStorage = (map, spec_id) => {
    return !map?.get(spec_id)?.some?.((k) => +k.goods_storage);
  };

  return (
    <div className="price-content">
      {/* 款式 */}
      {details?.goods_common_info?.spec_list?.[3] ? (
        <div className="goods-spec-item">
          <div className="spec-item-lists flex-w flex-center">
            <span className="mbottom10">款式：</span>
            {styleArr.map((item) => (
              <div key={item.list.spec_id}>
                <div
                  className={`spec-item-list spec-item-list-style mr10 mbottom10
                        ${+spec_id === +item.list.spec_id ? "style-active" : ""}
                       ${
                         lookStorage(styleMap, item.list?.spec_id) ||
                         lookState(styleMap, item.list?.spec_id)
                           ? "no-data"
                           : ""
                       }`}
                  onClick={() => {
                    if (+spec_id === +item.list.spec_id) {
                      setSpec_id(0);
                    } else {
                      setSpec_id(item.list.spec_id);
                    }
                  }}
                >
                  <span>{item.spec_name}</span>
                </div>
              </div>
            ))}
          </div>
          <Divider style={{ margin: "0 0 10px" }} />
        </div>
      ) : null}

      {/* 尺码 */}
      <div className="goods-spec-item">
        <div className="spec-item-lists">
          {sizeArr?.map((item) => {
            const arr = sizeMap?.get(item.list?.spec_id) || [];
            const goods = arr?.filter?.(
              (k) => +k?.goods_spec?.[3]?.spec_id === +spec_id || !spec_id
            );
            const min_price = minBy(goods, "goods_price")?.goods_price;
            const max_price = maxBy(goods, "goods_price")?.goods_price;
            const goods_state = goods?.some?.((k) => k.goods_state);
            const goods_storage = goods?.some?.((k) => k.goods_storage);
            return (
              <div
                key={item?.list?.spec_id}
                className={`spec-item-list  ${
                  !goods_state || !goods_storage ? "no-data-state" : ""
                }`}
                title={item?.spec_name || ""}
              >
                <span className="spec_name">{item.spec_name}</span>
                <span className="goods_price">
                  {min_price === max_price
                    ? "¥" + max_price
                    : "¥" + min_price + "~¥" + max_price}
                </span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Content;
