import http from "utils/request";
import payhttp from "utils/payrequest";

export const sms = (query = {}) => {
  return http.post("/api/member/sms", query);
};
export const login = (query = {}) => {
  return http.post("/api/member/login", query);
};
export const MemberLogin = (query = {}) => {
  return payhttp.post("/api/member/member_login", query);
};
export const SetPassword = (query = {}) => {
  return payhttp.post("/api/member/set_password", query);
};



export const register = (query = {}) => {
  return http.post("/api/member/register", query);
};
export const setting = (params = {}) => {
  return http.get("api/wap/mall_setting", { params });
}
export const gzhcode = (params = {}) => {
  return payhttp.get("api/gzhcode", { params });
};
export const gzhlogin = (query = {}) => {
  return payhttp.post("/api/gzh_login", query);
};
export const daylogin = (query = {}) => {
  return http.post("/api/member/day_login", query);
};