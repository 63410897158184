import { useLayoutEffect, useState } from 'react';
import { Anchor } from "antd";

// css
import "./index.less";


const { Link } = Anchor;
const LeftAffix = (props) => {
  const { template } = props
  const [top, setTop] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setTop(document.documentElement.scrollTop);
    }
    window.addEventListener("scroll", updateSize);
    updateSize();
    return () => window.removeEventListener("scroll", updateSize);
  }, []);
  const onClick = (e) => {
    e.preventDefault();
  };
  return (
    <Anchor
      affix={false}
      showInkInFixed={false}
      className={`new_home-leftAffix fz12 _e5272b pointer ${top > 1090 ? 'Affix-fix' : ''}`}
      offsetTop={100}
      style={{
        position: top > 1090 ? "fixed" : "absolute",
        top: top > 1090 ? "92px" : "1180px",
      }}
      onClick={onClick}
    >
      {template.map((k, i) => (
        <Link href={`#d${i}`} title={k.title} key={i} />
      ))}
    </Anchor>
  );
};
export default LeftAffix;
