// less
import "./index.less";

// module
import MainBottom from "../../components/MainBottom";

const Module5 = (props) => {
  const { datas } = props;

  const templateFn = (key) => {
    return (datas[key] || { template_common: [] })?.template_common || [];
  };
  return (
    <div className="new_home-module5">
      <div className="module5-top">
        <div className="module5-right">
          {templateFn("right")?.map?.((k, i) => (
            <div
              className="module5-right-item pointer"
              key={i}
              onClick={() => {
                window.open(k.template_url);
              }}
            >
              <img
                src={`${k.template_img}?x-oss-process=image/resize,m_lfit,h_300,w_300`}
                alt={k.template_name}
              />
              <div className="template-info">
                <div className="template-name owt">{k.template_name}</div>
                <div className="flex-b flex-center">
                  <div className="store-name">
                    <span>{k.store_name}</span>
                  </div>
                  <div className="goods-price">
                    <span className="money">¥</span>
                    {k.goods_price}
                  </div>
                </div>
              </div>
              <div className="store_code">
                <img src={k.store_code} alt="" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="new_home_main_bottom">
        <MainBottom data={templateFn("main_bottom_left")} />
        <MainBottom data={templateFn("main_bottom_right")} />
      </div>
    </div>
  );
};
export default Module5;
