// img
import dy from "assets/new_home/dy.png";
import sph from "assets/new_home/sph.png";
import tb from "assets/new_home/tb.png";
import tmall from "assets/new_home/tmall.png";
import ali from "assets/new_home/1688.png";
import pdd from "assets/new_home/pdd.png";
import wph from "assets/new_home/wph.png";
import sn from "assets/new_home/sn.png";
import mgj from "assets/new_home/mgj.png";
import xhs from "assets/new_home/xhs.png";
import xp from "assets/new_home/xp.png";
import jd from "assets/new_home/jd.png";
import yz from "assets/new_home/yz.png";
import wd from "assets/new_home/wd.png";
import wxxd from "assets/new_home/wxxd.png";

const uploadArr = [
  { icon: dy, title: "抖店" },
  { icon: sph, title: "视频号" },
  { icon: tb, title: "淘宝" },
  { icon: tmall, title: "天猫" },
  { icon: ali, title: "1688" },
  { icon: pdd, title: "拼多多" },
  { icon: wph, title: "唯品会" },
  { icon: sn, title: "苏宁易购" },
  { icon: mgj, title: "蘑菇街" },
  { icon: xhs, title: "小红书" },
  { icon: xp, title: "虾皮" },
  { icon: jd, title: "京东" },
  { icon: yz, title: "有赞" },
  { icon: wd, title: "微店" },
  { icon: wxxd, title: "微信小店" },
];
const ModuleUpload = () => {
  return (
    <div className="new_home-other">
      <div className="new_home-other-title">
        <div className="new_home-other-title-line"></div>
        <div className="title" style={{ margin: "0 20px" }}>
          支持全平台·一键上传
        </div>
        <div className="new_home-other-title-line"></div>
      </div>
      <div className="new_home-other-main">
        {uploadArr?.map?.((k, i) => (
          <div className="upload-item" title={k.title} key={i}>
            <img src={k.icon} alt="" />
          </div>
        ))}
      </div>
    </div>
  );
};
export default ModuleUpload;
