import { useEffect, useState, useRef } from "react";
import {
  Modal,
  Image as ImageAnt,
  message,
  Spin,
  Button,
  Space,
  Tooltip,
  Popover,
} from "antd";
import { CloseOutlined, QuestionCircleFilled } from "@ant-design/icons";

// util
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { minBy, maxBy } from "lodash";

// css
import "./index.less";

// api
import {
  GoodsImagesVideoV1,
  AddGoodsDownload,
  GoodsZipImages,
  GoodsDetailsSpu,
} from "api/merchandise";
import { GoodsDetails } from "api/merchandise";

// module
import Content from "./components/Content";

// img
import downImg from "assets/icon/downImg.png";
import look_search_img_icon1 from "assets/icon/look_search_img_icon1.png";
import copyImg from "assets/icon/copy2.png";

const BigImg = (props) => {
  const { type, visible, setVisible, goodsCommonId, colorId, goodsInfo } =
    props;

  const [spinning, setSpinning] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const [bigImgShow, setBigImgShow] = useState(false);
  const [bigImgShowIndex, setBigImgShowIndex] = useState(0);
  const [bigImgInfoShow, setBigImgInfoShow] = useState(false);
  const [bigImgInfoShowIndex, setBigImgInfoShowIndex] = useState(0);

  useEffect(() => {
    const getGoodsImagesVideoV1 = async (goods_common_id) => {
      setSpinning(true);
      const res = await GoodsImagesVideoV1({ goods_common_id });
      setSpinning(false);
      if (!res) return;
      if (res.ResponseStatus) return message.error(res.ResponseMsg);
      const arr = res.datas?.images_list || [];
      setAllImages(arr);
      if (type === 2) {
        setBigImgShowIndex(0);
        setBigImgShow(true);
      } else {
        const index = arr?.findIndex((k) => +k.color_id === +colorId);
        setBigImgShowIndex(index - 1);
        setBigImgInfoShow(true);
      }
    };
    if (visible && goodsCommonId) getGoodsImagesVideoV1(goodsCommonId);
  }, [visible, goodsCommonId, type, colorId]);

  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [details, setDetails] = useState({});
  useEffect(() => {
    const getGoodsDetails = async (goods_id) => {
      const res = await GoodsDetailsSpu({
        goods_id: goodsInfo?.goods_id,
      });
      const form = res?.datas || {};
      setDetails({ ...form });
      const arr = form?.spec_list_new?.map?.((k) => k.spec_value);
      const minPriceForm = minBy(arr, "goods_price");
      const maxPriceForm = maxBy(arr, "goods_price");
      setMinPrice(minPriceForm?.goods_price);
      setMaxPrice(maxPriceForm?.goods_price);
    };
    if (visible && goodsInfo?.goods_id) getGoodsDetails();
  }, [visible, goodsInfo?.goods_id]);

  // const imgChoosedLeft = () => {
  //   setBigImgShowIndex(() => {
  //     let index = bigImgShowIndex;
  //     if (index < allImages?.[0]?.goods_images?.length - 1) {
  //       index = index + 1;
  //     }
  //     return index;
  //   });
  // };
  // const imgChoosedRight = () => {
  //   if (bigImgShowIndex === 0) return;
  //   setBigImgShowIndex(bigImgShowIndex - 1);
  // };
  const getImageInfo = () => {
    setBigImgInfoShow(true);
  };

  const copyLoadImg = (imgSrc) => {
    AddGoodsDownload({
      type: 1,
      goods_common_id: goodsCommonId,
      browser: window.visitorId,
    });
    const src = imgSrc + `?t=${new Date().getTime()}`;

    var canvas = document.createElement("canvas");
    const image = new Image();
    image.src = src;
    image.setAttribute("crossOrigin", "Anonymous");
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      canvas.getContext("2d").drawImage(image, 0, 0);
      let url = canvas.toDataURL("image/png");
      fetch(url).then((res) => {
        res.blob().then(async (blob) => {
          if (!navigator?.clipboard?.write)
            return message.error(
              "此功能只有最新版本将浏览器可以使用！，请将浏览器升级到最新版本！"
            );
          await navigator.clipboard.write([
            new window.ClipboardItem({
              "image/png": blob,
            }),
          ]);
          message.success("复制成功");
        });
      });
    };
  };

  const downLoadImg = (imgSrc, name) => {
    AddGoodsDownload({
      type: 1,
      goods_common_id: goodsCommonId,
      browser: window.visitorId,
    });
    const src = imgSrc + `?t=${new Date().getTime()}`;
    var type = src.split(".").pop()?.split("?")?.[0];
    fetch(src).then((res) => {
      res.blob().then((myBlob) => {
        const href = URL.createObjectURL(myBlob);
        const a = document.createElement("a");
        a.href = href;
        a.download = name || "zyt_img_" + +new Date() + "." + type;
        a.click();
        a.remove();
      });
    });
  };

  // const bigImgInfoChoosedLeft = () => {
  //   setBigImgInfoShowIndex(() => {
  //     let index = bigImgInfoShowIndex;
  //     if (index < allImages?.[allImages + 1]?.goods_images?.length - 1) {
  //       index = index + 1;
  //     }
  //     return index;
  //   });
  // };
  // const bigImgInfoChoosedRight = () => {
  //   if (bigImgInfoShowIndex === 0) return;
  //   setBigImgInfoShowIndex(bigImgInfoShowIndex - 1);
  // };

  // 快速下图
  const convertImgToBase64 = (imgurl, callback, type) => {
    if (type === "video") {
      window.URL = window.URL || window.webkitURL;
      var xhr = new XMLHttpRequest();
      xhr.open("get", imgurl, true);
      xhr.responseType = "blob";
      xhr.crossOrigin = "Anonymous";
      xhr.onload = function () {
        if (this.status === 200) {
          var blob = this.response;
          callback(blob);
        } else {
          callback(null);
        }
      };
      xhr.send();
    } else {
      var canvas = document?.createElement?.("CANVAS"),
        ctx = canvas.getContext("2d"),
        img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = imgurl;
      img.onload = function (status) {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        dataURL = dataURL.replace("data:image/png;base64,", "");
        callback.call(this, dataURL);
        canvas = null;
      };
      img.onerror = (error) => {
        callback(null);
      };
    }
  };

  // 主图下载
  const downLoadMainImg = async () => {
    let imgs = allImages?.[0]?.goods_images?.map?.((k, i) => {
      return {
        img: k,
        name: allImages?.[i + 1]?.color_name,
      };
    });

    let key = "downLoadMainImg";
    let hides = message.loading({
      key,
      content: <p className="mtop8 fz14">正在生成中...</p>,
      duration: 0,
      className: "custom-class",
      icon: <Spin size="large" />,
    });
    let zip = new JSZip();
    let img = zip.folder(
      `${goodsInfo.store_name}_花型大图_${goodsInfo?.goods_name}`
    ); // name为压缩后解压出来的文件的名字
    for (let i = 0; i < imgs.length; i++) {
      // var index = imgs[i].img.lastIndexOf(".");
      // var ext = imgs[i].img.substr(index + 1)?.split?.("?")?.[0];
      const ext = "png";
      await new Promise((resolve, reject) => {
        convertImgToBase64(imgs[i].img, (imgData) => {
          if (imgData) {
            img.file(`${i + 1}_${imgs[i].name}.` + ext, imgData, {
              base64: true,
            });
            resolve();
          } else {
            reject();
          }
        });
      });
    }
    hides = message.loading({
      key,
      content: <p className="mtop8 fz14">正在生成压缩包...</p>,
      duration: 0,
      className: "custom-class",
      icon: <Spin size="large" />,
    });
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(
        content,
        `${goodsInfo.store_name}_花型大图_${goodsInfo?.goods_name}.zip`
      ); //压缩后的zip名
      setTimeout(hides, 200);
      AddGoodsDownload({
        type: 1,
        goods_common_id: goodsInfo.goods_common_id,
        browser: window.visitorId,
      });
    });
  };

  // 花型图下载
  const downLoadSkuImg = async () => {
    let imgs = allImages?.[bigImgShowIndex + 1]?.goods_images || [];
    let key = "downLoadSkuImg";
    let hides = message.loading({
      key,
      content: <p className="mtop8 fz14">正在生成中...</p>,
      duration: 0,
      className: "custom-class",
      icon: <Spin size="large" />,
    });
    let zip = new JSZip();
    let img = zip.folder(
      `${goodsInfo.store_name}_花型细节图_${
        allImages?.[bigImgShowIndex + 1]?.color_name
      }`
    ); // name为压缩后解压出来的文件的名字
    for (let i = 0; i < imgs.length; i++) {
      var index = imgs[i].lastIndexOf(".");
      var ext = imgs[i].substr(index + 1)?.split?.("?")?.[0];
      ext = "png";
      // eslint-disable-next-line no-loop-func
      await new Promise((resolve, reject) => {
        convertImgToBase64(imgs[i], (imgData) => {
          if (imgData) {
            img.file(`图${i + 1}.` + ext, imgData, { base64: true });
            resolve();
          } else {
            reject();
          }
        });
      });
    }

    hides = message.loading({
      key,
      content: <p className="mtop8 fz14">正在生成压缩包...</p>,
      duration: 0,
      className: "custom-class",
      icon: <Spin size="large" />,
    });
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(
        content,
        `${goodsInfo.store_name}_花型细节图_${
          allImages?.[bigImgShowIndex + 1]?.color_name
        }.zip`
      ); //压缩后的zip名
      setTimeout(hides, 200);
      AddGoodsDownload({
        type: 1,
        goods_common_id: goodsInfo.goods_common_id,
        browser: window.visitorId,
      });
    });
  };

  // 全系列下图
  const timer2 = useRef(null);
  const time2 = useRef(null);
  const downUrl1 = async () => {
    const res = await GoodsDetails({
      visitor_id: window.visitorId,
      goods_id: goodsInfo?.goods_id,
    });

    const url = res?.datas?.goods_common_info?.download_url;
    const type = 3;
    const goods_common_id = goodsInfo?.goods_common_id;

    if (time2.current && time2.current < 10) {
      return message.warning("操作过于频繁，请10s后再试");
    }
    AddGoodsDownload({ type, goods_common_id, browser: window.visitorId });
    const a = document.createElement("a");
    a.style.display = "none";
    a.setAttribute("target", "_blank");
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    clearInterval(timer2.current);
    time2.current = 0;
    timer2.current = setInterval(() => {
      if (time2.current >= 60) {
        time2.current = 0;
        clearInterval(timer2.current);
        return;
      }
      time2.current++;
    }, 1000);
  };

  // 快速下图
  const [counts, setCounts] = useState(0);
  const [base64Counts, setBase64Counts] = useState(0);
  const [hide, setHide] = useState(null);
  const [zips, setZips] = useState(null);
  const [datas, setDatas] = useState(null);
  const [downType, setDownType] = useState(1);
  let base64Count = 0;
  const key = "updatable";
  useEffect(() => {
    let hides = "";
    if (base64Counts) {
      hides = message.loading({
        key,
        content: (
          <p className="mtop8 fz14">
            正在加载中 {+((base64Counts / (counts || 1)) * 100).toFixed(0)}%
          </p>
        ),
        duration: 0,
        className: "custom-class",
        icon: <Spin size="large" />,
      });
    }
    if (base64Counts !== counts || !base64Counts) return;
    hides = message.loading({
      key,
      content: <p className="mtop8 fz14">正在生成压缩包...</p>,
      duration: 0,
      className: "custom-class",
      icon: <Spin size="large" />,
    });
    setHide(() => {
      return hides;
    });
    // if(!quickDownLoadSure)return;
    zips.generateAsync({ type: "blob" }).then((content) => {
      saveAs(
        content,
        `${goodsInfo.store_name}_${downType === 1 ? "快速下图" : "视频下载"}_${
          datas.goods_name || goodsInfo.goods_name + "_video"
        }`
      ); //压缩后的zip名
      setTimeout(hides, 200);
      AddGoodsDownload({
        type: downType,
        goods_common_id: goodsInfo.goods_common_id,
        browser: window.visitorId,
      });
      // console.log(+new Date());
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [base64Counts]);
  const quickDown = async (goods_common_id) => {
    // console.log(+new Date());
    // setBase64Counts(0)

    setHide(() => {
      return message.loading({
        key,
        content: <p className="mtop8 fz14">正在生成中...</p>,
        duration: 0,
        className: "custom-class",
        icon: <Spin size="large" />,
      });
    });
    const res = await GoodsZipImages({ goods_common_id });
    if (!res) return;
    if (res.ResponseStatus) {
      setTimeout(hide, 60);
      return message.error(res.ResponseMsg);
    }
    const datas = res.datas;
    setDatas(datas);
    setCounts(() => {
      let count = 0;
      count += datas?.infos?.length || 0;
      datas.sku.forEach((k) => {
        count += k.images.length;
      });
      count += datas.sku.length;
      return count;
    });

    let zip = new JSZip();
    setDownType(1);

    setZips(zip);
    datas?.infos?.forEach?.((k, i) => {
      var index = k.lastIndexOf(".");
      var ext = k.substr(index + 1)?.split?.("?")?.[0];
      ext = "png";

      convertImgToBase64(k, (imgData) => {
        if (imgData)
          zip.file(`详情页_${i + 1}.` + ext, imgData, { base64: true });
        base64Count++;
        setBase64Counts(base64Count);
      });
    });
    datas.sku.forEach((k1, i1) => {
      k1.images.forEach((k, i) => {
        var index = k.lastIndexOf(".");
        var ext = k.substr(index + 1)?.split?.("?")?.[0];
        ext = "png";

        convertImgToBase64(k, (imgData) => {
          if (imgData)
            zip.file(`${k1.sku_name}_${i + 1}.` + ext, imgData, {
              base64: true,
            });
          base64Count++;
          setBase64Counts(base64Count);
        });
      });
    });
    let img = zip.folder(`主图`); // name为压缩后解压出来的文件的名字
    datas.sku.forEach((k, i) => {
      var index = k.images[0].lastIndexOf(".");
      var ext = k.images[0].substr(index + 1)?.split?.("?")?.[0];
      ext = "png";

      convertImgToBase64(k.images[0], (imgData) => {
        if (imgData)
          img.file(`${i + 1}_${k.sku_name}.` + ext, imgData, { base64: true });
        base64Count++;
        setBase64Counts(base64Count);
      });
    });
  };

  return (
    <div>
      <Modal
        visible={bigImgShow}
        destroyOnClose
        title="查看花型大图"
        okText="确定"
        cancelText="取消"
        footer={null}
        width={1400}
        closeIcon={<CloseOutlined className="fz24" />}
        onCancel={() => {
          setVisible(false);
          setBigImgShow(false);
        }}
        className="modal"
        style={{ top: 10 }}
      >
        <Spin spinning={spinning} tip="获取数据中...">
          <div
            className="goodsInfo"
            style={{
              MozUserSelect: "none",
              WebkitUserSelect: "none",
              msUserSelect: "none",
              paddingTop: 0,
            }}
          >
            <div className="mtop10 flex-start">
              <div className="bigImages textC">
                <ImageAnt
                  preview={false}
                  width={620}
                  height={620}
                  style={{ objectFit: "cover" }}
                  src={allImages?.[0]?.goods_images?.[bigImgShowIndex]}
                ></ImageAnt>
                {/* <div
                  className="arrow arrow-left"
                  onClick={() => {
                    imgChoosedLeft();
                  }}
                >
                  <LeftOutlined />
                </div>
                <div
                  className="arrow arrow-right"
                  onClick={() => {
                    imgChoosedRight();
                  }}
                >
                  <RightOutlined />
                </div> */}
              </div>

              <div className="flex1">
                <div className="flex flex-b flex-center mbottom10">
                  <div className="ml10 fz18 flex-center">
                    <span className="fw700">花型大图</span>
                    <span
                      style={{
                        color: "#F0423F",
                        fontSize: 22,
                        marginLeft: 8,
                        marginRight: 8,
                      }}
                    >
                      ¥{minPrice} ~ ¥{maxPrice}
                    </span>
                    <Tooltip title="该商品的最低价-最高价区间">
                      <QuestionCircleFilled style={{ color: "#999" }} />
                    </Tooltip>
                  </div>
                  <Space>
                    <Popover
                      placement="bottom"
                      trigger="click"
                      content={<Content details={details} />}
                    >
                      <Button danger>详细尺寸及价格</Button>
                    </Popover>

                    <Button type="primary" onClick={downLoadMainImg}>
                      下载当前页图片
                    </Button>
                  </Space>
                </div>
                <div
                  className="flex-w pointer"
                  style={{
                    maxHeight: 620,
                    overflowY: "auto",
                  }}
                >
                  {allImages?.[0]?.goods_images?.map?.((item, index) => (
                    <div key={index}>
                      <div className="textC">
                        <div
                          style={{
                            borderRadius: 2,
                            backgroundColor: "#f6f6f6",
                            margin: "0 0 10px 10px",
                            overflow: "hidden",
                            border:
                              bigImgShowIndex === index
                                ? "2px solid #dd5148"
                                : "2px solid #ccc",
                          }}
                          className="copy-img-f copy-img-f-spu"
                          onClick={() => {
                            getImageInfo();
                          }}
                          onMouseOver={() => {
                            setBigImgShowIndex(index);
                          }}
                        >
                          <img
                            style={{
                              width: 120,
                              height: 120,
                              objectFit: "cover",
                            }}
                            // src={item+'?x-oss-process=image/resize,m_lfit,h_240,w_240'}
                            src={item} // 单独加载图片慢，先全部一起加载
                            alt=""
                            onSelect={() => {
                              return false;
                            }}
                          />
                          <div
                            className="look-search-img"
                            onClick={() => {
                              window.open(`/searchImg?imgurl=${item}`);
                            }}
                          >
                            <img
                              className="no-select"
                              src={look_search_img_icon1}
                              alt=""
                              style={{ width: 12, height: 12 }}
                            />
                            <div>找同款</div>
                          </div>

                          <div
                            className="copy-img-icon"
                            title="下载图片"
                            onClick={(e) => {
                              e.stopPropagation();
                              downLoadImg(
                                item,
                                allImages?.[index + 1]?.color_name
                              );
                            }}
                            style={{
                              right: 27,
                            }}
                          >
                            <img
                              src={downImg}
                              style={{ wdith: 12, height: 12 }}
                              alt=""
                            />
                          </div>

                          <div
                            className="copy-img-icon"
                            title="复制图片"
                            onClick={(e) => {
                              e.stopPropagation();
                              copyLoadImg(item);
                            }}
                          >
                            <img
                              src={copyImg}
                              style={{ wdith: 12, height: 12 }}
                              alt=""
                            />
                          </div>

                          <div
                            className="hover-red"
                            style={{ width: 120 }}
                            onClick={() => {
                              getImageInfo();
                            }}
                          >
                            {allImages?.[index + 1]?.color_name}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="textC">
              <Space className="mtop10">
                <Button
                  type="primary"
                  onClick={() => {
                    window.open(`/merchandise/${goodsInfo?.goods_id}`);
                  }}
                >
                  查看商品详情
                </Button>
                <Button type="primary" onClick={downUrl1}>
                  全系列下载
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    quickDown(goodsInfo?.goods_common_id);
                  }}
                >
                  快速下载
                </Button>
              </Space>
            </div>
          </div>
        </Spin>
      </Modal>
      <Modal
        visible={bigImgInfoShow}
        destroyOnClose
        title="查看花型细节图"
        okText="确定"
        cancelText="取消"
        footer={null}
        width={1400}
        closeIcon={<CloseOutlined className="fz24" />}
        onCancel={() => {
          setBigImgInfoShow(false);
          setVisible(false);
        }}
        className="modal"
        style={{ top: 10 }}
      >
        <div
          className="goodsInfo"
          style={{
            MozUserSelect: "none",
            WebkitUserSelect: "none",
            msUserSelect: "none",
            paddingTop: 0,
          }}
        >
          <div className="mtop10 flex-start">
            <div className="bigImages textC">
              <div
                className="textL ml10 fw700 mbottom10 fz16"
                style={{ width: "100%" }}
              >
                <span>花型主图</span>
              </div>
              <ImageAnt
                preview={false}
                width={620}
                height={620}
                style={{ objectFit: "cover" }}
                src={
                  allImages?.[bigImgShowIndex + 1]?.goods_images?.[
                    bigImgInfoShowIndex
                  ]
                }
              ></ImageAnt>
              {/* <div className="flex-center flex-centerY">
                <div
                  className="arrow arrow-left"
                  onClick={() => {
                    bigImgInfoChoosedLeft();
                  }}
                >
                  <RightOutlined />
                </div>
                <div
                  className="arrow arrow-right"
                  onClick={() => {
                    bigImgInfoChoosedRight();
                  }}
                >
                  <LeftOutlined />
                </div>
              </div> */}
            </div>
            <div className="flex1">
              <div className="flex-center flex-w">
                <div className="textL ml10  mbottom10 fz16 flex1">
                  <Popover
                    placement="top"
                    content={<span>点击查看其他花型图</span>}
                    visible={true}
                  >
                    <span
                      className="pointer hover-red fw700"
                      style={{ color: "#1162FE" }}
                      onClick={() => {
                        setVisible(true);
                        setBigImgInfoShow(false);
                        setBigImgShow(true);
                      }}
                    >
                      花型主图
                    </span>
                  </Popover>
                  <span className="ml4 mr4">&gt;</span>
                  <span className="fz16">
                    {allImages?.[bigImgShowIndex + 1]?.color_name}
                  </span>

                  <span
                    style={{
                      color: "#F0423F",
                      fontSize: 16,
                      marginLeft: 8,
                      marginRight: 8,
                    }}
                  >
                    ¥{allImages?.[bigImgShowIndex + 1]?.min_price} ~ ¥
                    {allImages?.[bigImgShowIndex + 1]?.max_price}
                  </span>
                  <Tooltip title="该商品的最低价-最高价区间">
                    <QuestionCircleFilled style={{ color: "#999" }} />
                  </Tooltip>
                </div>
                <Space>
                  <Popover
                    placement="bottom"
                    trigger="click"
                    content={<Content details={details} color_id={allImages?.[bigImgShowIndex + 1]?.color_id} />}
                  >
                    <Button danger>详细尺寸及价格</Button>
                  </Popover>
                  <Button type="primary" onClick={downLoadSkuImg}>
                    下载当前页图片
                  </Button>
                </Space>
              </div>

              <div
                className="flex-w"
                style={{ width: "100%", maxHeight: 620, overflowY: "auto" }}
              >
                {allImages?.[bigImgShowIndex + 1]?.goods_images?.map?.(
                  (item, index) => (
                    <div
                      key={index}
                      className="copy-img-f"
                      style={{
                        border:
                          bigImgInfoShowIndex === index
                            ? "2px solid #dd5148"
                            : "2px solid #ccc",
                      }}
                      onMouseOver={() => {
                        setBigImgInfoShowIndex(index);
                      }}
                    >
                      <img
                        style={{ width: 120, height: 120, objectFit: "cover" }}
                        // src={item+'?x-oss-process=image/resize,m_lfit,h_240,w_240'}
                        src={item} // 单独加载图片慢，先全部一起加载
                        alt=""
                      />
                      <div
                        className="look-search-img"
                        onClick={() => {
                          window.open(`/searchImg?imgurl=${item}`);
                        }}
                      >
                        <img
                          className="no-select"
                          src={look_search_img_icon1}
                          alt=""
                          style={{ width: 12, height: 12 }}
                        />
                        <div>找同款</div>
                      </div>

                      <div
                        className="copy-img-icon"
                        title="下载图片"
                        onClick={(e) => {
                          e.stopPropagation();
                          downLoadImg(
                            item,
                            allImages?.[bigImgShowIndex + 1]?.color_name +
                              "_" +
                              (index + 1)
                          );
                        }}
                        style={{
                          right: 27,
                        }}
                      >
                        <img
                          src={downImg}
                          style={{ wdith: 12, height: 12 }}
                          alt=""
                        />
                      </div>

                      <div
                        className="copy-img-icon"
                        title="复制图片"
                        onClick={() => {
                          copyLoadImg(item);
                        }}
                      >
                        <img
                          src={copyImg}
                          style={{ wdith: 12, height: 12 }}
                          alt=""
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default BigImg;
