import http from "utils/request";

// article
export const GetArticleInfo = (params = {}) => {
  return http.get("/api/wap/get_article_info", { params });
}; 
export const GetArticleClass = (params = {}) => {
  return http.get("/api/wap/get_article_class", { params });
}; 
export const GetArticleClassList = (params = {}) => {
  return http.get("/api/wap/get_article_class_list", { params });
}; 
export const GetArticleList = (params = {}) => {
  return http.get("/api/wap/get_article_list", { params });
}; 

// 运费模板
export const GetDfTransport = (params = {}) => {
  return http.get("/api/member/get_df_transport", { params });
}; 
// 运费计算
export const GetDfTransportPrice = (params = {}) => {
  return http.post("/api/member/df_transport_price", params );
}; 
