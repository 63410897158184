import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Image } from "antd";
import { MobileOutlined, CaretDownOutlined } from "@ant-design/icons";
import { AddDataOverview } from "api/home";

// utils
import Storage from "utils/storage";

// less
import "./nav.less";

// img
import icon from "assets/new_home/icon1.png";

const Nav = (props) => {
  const { data, member } = props;
  const history = useHistory();

  const [pathName, setPathName] = useState("");
  useEffect(() => {
    window.StartTime = +new Date();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      AddDataOverview({
        visitor_id: window.visitorId,
        start_time: +(window.StartTime / 1000)?.toFixed(0),
        end_time: +(window.StartTime / 1000)?.toFixed(0),
        duration: 0,
        page_name: history?.location?.pathname?.split?.("/")?.[1],
        searchs: history?.location?.pathname?.split?.("/")?.[2],
        channel: 1,
      });

      setPathName(history?.location?.pathname);
      if (pathName === history?.location?.pathname || !pathName) return;

      setTimeout(() => {
        window.StartTime = +new Date();
      }, 60);
      window.EndTime = +new Date();
      AddDataOverview({
        visitor_id: window.visitorId,
        start_time: +(window.StartTime / 1000)?.toFixed(0),
        end_time: +(window.EndTime / 1000)?.toFixed(0),
        duration: +((window.EndTime - window.StartTime) / 1000)?.toFixed(0),
        page_name: history?.location?.pathname?.split?.("/")?.[1],
        searchs: history?.location?.pathname?.split?.("/")?.[2],
        channel: 1,
      });
    }, 120);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history?.location]);

  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]');
    favicon.href = global.private
      ? "https://api.fangzhifu.com/fangzhifu.ico"
      : "https://osss.fangzhifu.com/mall/bitbug_favicon1.ico";
  }, [data]);

  // 前去注册登录
  const goLogin = (type) => {
    if (type === 1) {
      history.push("/login", { type });
    } else {
      history.push("/register");
    }
  };
  const goOut = () => {
    Storage.removeUser();
    Storage.removeKS();
    Storage.removeDY();
    window.location.replace("/");
  };
  const goPerson = (path) => {
    window.open(path);
  };

  const setInit = () => {
    const uname = member.token
      ? member.member_name
      : "游客" + (window.visitorId || +new Date());
    const tel = member.token ? member.member_mobile : "";
    const face = member.token ? member.member_logo : "";
    setTimeout(() => {
      window.zc("config", {
        groupid: "1",
        custom: true,
        uname: uname,
        tel: tel,
        face: face,
      });
    }, 60);
  };

  // MobileDown
  const MobileDown = () => {
    return (
      <ul className="mobileDown flex-b">
        <li>
          <Image width={120} preview={false} src={data?.mall_wx_logo} />
          <div>官方微信小程序</div>
        </li>
        <li>
          <Image width={120} preview={false} src={data?.mall_gzh_logo} />
          <div>官方微信公众号</div>
        </li>
        <li>
          <Image width={120} preview={false} src={data?.mall_app_logo} />
          <div>官方App</div>
        </li>
      </ul>
    );
  };

  // KefuDown
  const KefuDown = () => {
    return (
      <div className="mobileDown1 textC" style={{ width: 120 }}>
        <div className="flex-center flex-centerY mtop10 mbottom8">
          <img src={icon} style={{ width: 16, marginTop: 1 }} alt="" />
          <div
            className="pointer zhiCustomBtn"
            style={{
              height: 20,
              lineHeight: "20px",
              borderRadius: 16,
              backgroundColor: "#FF8F00",
              color: "#fff",
              fontSize: 12,
              padding: "0 12px",
              marginLeft: 2,
            }}
            onClick={setInit}
          >
            在线客服
          </div>
        </div>
        <Image width={102} preview={false} src={data?.mall_wx} />
        <div className="_999" style={{ lineHeight: "18px" }}>
          扫码加客服微信
        </div>
      </div>
    );
  };

  return (
    <div className="nav-new">
      <div className="container flex-b">
        <div className="left">
          {/* <span
            className="logo pointer"
            onClick={() => {
              history.push("/");
            }}
          >
            <Image width={80} height={18} preview={false} src={data.hong_logo} style={{ objectFit: 'contain' }} />
          </span> */}
          <span className="welcome mr10">
            欢迎来到
            <a
              href="/"
              className="primary-color pointer fw700"
              style={{ textDecoration: "underline" }}
            >
              {data.mall_name}
            </a>
            官网
          </span>
          {member.token ? (
            <a href="/myorderlist" className="mr10 primary-color pointer">
              [{member.member_name}]
            </a>
          ) : (
            <span
              className="go-login primary-color-hover pointer mr10"
              onClick={() => {
                goLogin(1);
              }}
            >
              请登录
            </span>
          )}
          {member.token ? (
            <span
              className="go-login primary-color-hover pointer mr10"
              onClick={() => {
                goOut(1);
              }}
            >
              [退出]
            </span>
          ) : (
            <span
              className="register pointer primary-color mr10"
              onClick={() => {
                goLogin(2);
              }}
            >
              免费注册
            </span>
          )}
        </div>
        <div className="right flex-b">
          {global.private ? null : (
            <span
              className="primary-color-hover pointer mr10 primary-color"
              onClick={() => {
                goPerson("/brandapply");
              }}
            >
              商家入驻申请
            </span>
          )}
          {global.private ? null : <span className="line mr10"></span>}
          {global.private ? null : (
            <div>
              <div className="nav-item">
                <div
                  className="pointer mr10"
                  onClick={() => {
                    goPerson("/freight");
                  }}
                >
                  <span>运费测算</span>
                  <CaretDownOutlined className="down-icon" />
                </div>
                <ul className="more-down">
                  <li className="pointer hover-red">
                    <i
                      className="_666 hover-red"
                      onClick={() => {
                        goPerson("/offProducts");
                      }}
                    >
                      下架查询
                    </i>
                  </li>
                </ul>
              </div>
            </div>
          )}

          {global.private ? null : <span className="line mr10"></span>}
          <div className="nav-item">
            <div className="pointer mr10">
              <span>我的商城</span>
              <CaretDownOutlined className="down-icon" />
            </div>
            <ul className="more-down">
              <li className="pointer hover-red">
                <i
                  className="_666 hover-red"
                  onClick={() => {
                    goPerson("/apply");
                  }}
                >
                  车贴费申领
                </i>
              </li>

              <li className="pointer hover-red">
                <i
                  className="_666 hover-red"
                  onClick={() => {
                    goPerson("/myorderlist");
                  }}
                >
                  我的订单
                </i>
              </li>
              <li className="pointer hover-red">
                <i
                  className="_666 hover-red"
                  onClick={() => {
                    goPerson("/myorderlist");
                  }}
                >
                  会员中心
                </i>
              </li>
            </ul>
          </div>
          <span className="line mr10"></span>

          {global.private ? (
            <span
              className="primary-color-hover pointer mr10 primary-color"
              onClick={() => {
                goPerson("/goodsfav");
              }}
            >
              商品收藏
            </span>
          ) : null}
          {global.private ? <span className="line mr10"></span> : null}

          {global.private ? null : (
            <div>
              <div className="nav-item">
                <div className="pointer mr10">
                  <span>我是商家</span>
                  <CaretDownOutlined className="down-icon" />
                </div>
                <ul className="more-down">
                  <li className="pointer hover-red">
                    <i
                      className="_666 hover-red"
                      onClick={() => {
                        goPerson(JSON.parse(Storage.getSetting()).seller_login);
                      }}
                    >
                      商家登录
                    </i>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {global.private ? null : <span className="line mr10"></span>}

          {global.private ? null : (
            <span
              className="primary-color-hover pointer mr10 primary-color"
              onClick={() => {
                window.open("http://www.zyterp.com/warehouse/login");
              }}
            >
              找源头ERP
            </span>
          )}
          {global.private ? null : <span className="line mr10"></span>}
          {global.private ? null : (
            <div
              className="nav-item primary-color-hover"
              onMouseEnter={() => {
                setTimeout(() => {
                  window.zc("dynamic_ready");
                }, 60);
              }}
            >
              <div className="pointer mr10 danger-color">
                <span>在线客服</span>
              </div>
              <KefuDown />
            </div>
          )}
          {global.private ? null : <span className="line mr10"></span>}
          {global.private ? null : (
            <div className="mr20">
              服务热线：
              <span className="primary-color">
                {data?.zyt_phone || "400-992-9892"}
              </span>
            </div>
          )}
          {global.private ? null : (
            <div className="nav-item">
              <div className="pointer mr10">
                <MobileOutlined />
                <span>手机版</span>
              </div>
              <MobileDown />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Nav;
