import { useState, useEffect } from "react";
import { Image } from "antd";

import "./PrivateTop.less";
import PrivateBanner from "pages/common/Privatebanner/Privatebanner";
import { StoreNavigation } from "api/store";


const PrivateTop = (props) => {
  const { setQuery, query, setActive1, setActive2 } = props
  const [data, setData] = useState([]);
  useEffect(() => {
    return getNavigation();
  }, []);
  const getNavigation = async () => {
    const res = await StoreNavigation();
    if (res.ResponseStatus !== 0) return;
    setData([...(res?.datas || [])]);
  };

  const goOther = (url, i) => {
    // window.open(`${url}&ni=${i}`);
    window.open(url);
  };

  return (
    <div className="PrivateTop _fff">
      <div className="container flex">
        <div className="all fz12 flex-center pointer prela">
          <Image
            width={15}
            preview={false}
            src="https://osss.fangzhifu.com/mall/navBar.png"
          />
          <span className="ml10 fw700 fz16">全部商品类目</span>
          <PrivateBanner 
            setQuery={setQuery} 
            query={query} 
            setActive1={setActive1}
            setActive2={setActive2}
          />
        </div>
        <ul className="others fz16 flex fw700">
          {data.map((k, i) => (
            <li
              key={i}
              className={`other hover-red hover-line pointer plate`}
              onClick={() => {
                goOther(k.url, i);
              }}
            >
              {k.navigation_name}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
export default PrivateTop;
