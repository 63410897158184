import { useState, useMemo } from "react";

// css
import "./index.less";
import hotShop from "assets/imgs/hot_shop.png";

// api
import { GetStoreBGoods } from "api/store";

const ContShopLeft = (props) => {
  const { store_id } = props;

  const [storeGoodsNew, setStoreGoodsNew] = useState([]);

  useMemo(() => {
    // 店铺新品
    const getStoreBGoods = async () => {
      const res = await GetStoreBGoods({ store_id });
      setStoreGoodsNew(res?.datas || []);
    };

    if (store_id) getStoreBGoods();
  }, [store_id]);

  return storeGoodsNew?.length ? (
    <div className="stores-goods">
      {/* 店铺新品 */}
      {/* <h3 className="store-goods-title">店铺爆款花型</h3> */}
      <div>
        <img className="store-goods-title-img" src={hotShop} alt="找源头" />
      </div>
      <div className="store-goods-main">
        {storeGoodsNew?.map?.((k, i) => (
          <a
            target="blank"
            href={`/merchandise/${k.goods_id}?is_b=1`}
            className="store-goods-item"
            key={i}
          >
            <div className="shop_tag">爆</div>
            <div className="store-goods-img">
              <img src={k.goods_images} alt="" />
              <div className="store-goods-name owt">{k.goods_name}</div>
            </div>

            <div className="store-goods-price">
              ¥<span>{k.goods_price}</span>
            </div>
          </a>
        ))}
      </div>
    </div>
  ) : (
    <div style={{ width: 268 }} />
  );
};
export default ContShopLeft;
