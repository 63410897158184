import moment from 'moment';

// 近n天
export const getDate = (days) => {
  const date = new Date(Date.now() - 3600 * 1000 * 24 * days);
  const start = moment(date).format('YYYY-MM-DD') + ' 00:00:00';
  const end =
    (days === 1 ? moment(date).format('YYYY-MM-DD') : moment(+new Date()).format('YYYY-MM-DD')) +
    ' 23:59:59';
  const arr = [moment(start, 'YYYY-MM-DD HH:mm:ss'), moment(end, 'YYYY-MM-DD HH:mm:ss')];
  return {
    start,
    end,
    arr,
  };
};

export const getDateTime = (type) => {
  let timestamp = Date.parse(new Date());
  let date = new Date(timestamp);
  date.setDate(date.getDate());
  var Y = date.getFullYear();
  var M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  let todayDate = Y + '-' + M + '-' + D;
  return todayDate;
};

export const ranges = ({ yesterday, today, three_days, week }) => {
  let ranges = {
    昨日: null,
    今日: null,
    近三天: null,
    本周: null,
  };

  // 昨日
  const date1 = new Date(Date.now() - 3600 * 1000 * 24 * 1);
  let year1 = date1.getFullYear();
  let month1 = date1.getMonth() + 1;
  let day1 = date1.getDate();
  // 今日
  const date = new Date();
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  // 近三天
  const date3 = new Date(Date.now() - 3600 * 1000 * 24 * 2);
  let year3 = date3.getFullYear();
  let month3 = date3.getMonth() + 1;
  let day3 = date3.getDate();

  if (yesterday) {
    let start = year1 + '-' + month1 + '-' + day1 + ' 00:00:00';
    let end = year1 + '-' + month1 + '-' + day1 + ' 23:59:59';
    let arr = [moment(start, 'YYYY-MM-DD HH:mm:ss'), moment(end, 'YYYY-MM-DD HH:mm:ss')];
    ranges['昨日'] = arr;
  } else {
    delete ranges['昨日'];
  }
  if (today) {
    let start = year + '-' + month + '-' + day + ' 00:00:00';
    let end = year + '-' + month + '-' + day + ' 23:59:59';
    let arr = [moment(start, 'YYYY-MM-DD HH:mm:ss'), moment(end, 'YYYY-MM-DD HH:mm:ss')];
    ranges['今日'] = arr;
  } else {
    delete ranges['今日'];
  }
  if (three_days) {
    let start = year3 + '-' + month3 + '-' + day3 + ' 00:00:00';
    let end = year + '-' + month + '-' + day + ' 23:59:59';
    let arr = [moment(start, 'YYYY-MM-DD HH:mm:ss'), moment(end, 'YYYY-MM-DD HH:mm:ss')];
    ranges['近三天'] = arr;
  } else {
    delete ranges['近三天'];
  }
  if (week) {
    ranges['本周'] = [moment().startOf('week'), moment().endOf('week')];
  } else {
    delete ranges['本周'];
  }
  // console.log('ranges', ranges);
  return { ...ranges };
};


// 延迟
export const delay = (duration) => new Promise((resolve) => setTimeout(resolve, duration));
