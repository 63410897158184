import store from 'store'

const USER_KEY = 'member'
const SETTING_KEY = "setting";
const KS_KEY = "ks_code";
const DY_KEY = "dy_code";
const PDD_KEY = "pdd_code";
const TIME_KEY = "time";
const PRIVATE_KEY = "private";
const FACE_KEY = "face";
const PROMPT_KEY = "promrt";
let storage = {
  setUser(user) {
    store.set(USER_KEY, user);
  },
  getUser() {
    return store.get(USER_KEY) || null;
  },
  removeUser() {
    store.remove(USER_KEY);
    store.remove("activity-time")
  },
  setSetting(setting) {
    store.set(SETTING_KEY, setting);
  },
  getSetting() {
    return store.get(SETTING_KEY) || null;
  },
  removeSetting() {
    store.remove(SETTING_KEY);
  },
  setKS(ks) {
    store.set(KS_KEY, ks);
  },
  getKS() {
    return store.get(KS_KEY) || null;
  },
  removeKS() {
    store.remove(KS_KEY);
  },
  setDY(dy) {
    store.set(DY_KEY, dy);
  },
  getDY() {
    return store.get(DY_KEY) || null;
  },
  removeDY() {
    store.remove(DY_KEY);
  },
  setTIME(TIME) {
    store.set(TIME_KEY, TIME);
  },
  getTIME() {
    return store.get(TIME_KEY) || null;
  },
  removeTIME() {
    store.remove(TIME_KEY);
  },
  setPDD(PDD) {
    store.set(PDD_KEY, PDD);
  },
  getPDD() {
    return store.get(PDD_KEY) || null;
  },
  removePDD() {
    store.remove(PDD_KEY);
  },
  setPRIVATE(PRIVATE) {
    store.set(PRIVATE_KEY, PRIVATE);
  },
  getPRIVATE() {
    return store.get(PRIVATE_KEY) || null;
  },
  removePRIVATE() {
    store.remove(PRIVATE_KEY);
  },
  setFACE(FACE) {
    store.set(FACE_KEY, FACE);
  },
  getFACE() {
    return store.get(FACE_KEY) || null;
  },
  removeFACE() {
    store.remove(FACE_KEY);
  },
  setPrompt(prompt) {
    store.set(PROMPT_KEY, prompt);
  },
  getPrompt() {
    return store.get(PROMPT_KEY) || null;
  },
  removePrompt() {
    store.remove(PROMPT_KEY);
  },
};
export default storage;