import axios from "axios";
import md5 from "js-md5";
import "config/global";
import Storage from "utils/storage";

const APPKEY = "base64:NjJf3uVoLWz7o6s7RrNTIiXGJdtZ+NHo/2j4RxPPoBU=";

const http = axios.create({
  baseURL: global.payUrl,
  timeout: "60000",
});
let qpsMap = new Map();
const qpsController = async (config) => {
  const user = Storage.getUser("member");
  var user_info = JSON.parse(user);
  // var face = Storage.getFACE()
  if (user_info == null) {
    user_info = {};
    user_info.token = "";
  }
  // user_info.face = face || 1
  
  let yy = new Date().getFullYear();
  let mm = new Date().getMonth() + 1;
  let dd = new Date().getDate();
  let hh = new Date().getHours();
  let mf =
    new Date().getMinutes() < 10
      ? "0" + new Date().getMinutes()
      : new Date().getMinutes();
  let ss =
    new Date().getSeconds() < 10
      ? "0" + new Date().getSeconds()
      : new Date().getSeconds();
  let gettime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
  var sign = "";
  if (config.method === "get") {
    // console.log('sss-',window.location.protocol);
    if(config.url.includes('get_platform_token')){
      config.params.domain = document.domain
      config.params.protocol = window.location.protocol
    }
    config.params.timestamp = gettime;
    config.params.is_pc = 1;
    config.params.token = user_info.token;
    // config.params.face = user_info.face;
    if (config.params.length !== 0) {
      const ordered = {};
      //排序
      Object.keys(config.params)
        .sort()
        .forEach(function (key) {
          if (key !== "sign") {
            ordered[key] = config.params[key];
          }
        });
      //组合
      Object.keys(ordered).forEach((key) => {
        // console.log(ordered[key]);
        if (ordered[key] !== "") {
          if (ordered[key] instanceof Array) {
            sign += JSON.stringify(ordered[key]);
          } else if (ordered[key] instanceof Object) {
            sign += JSON.stringify(ordered[key]);
          } else if (
            ordered[key] !== "" &&
            ordered[key] !== null &&
            ordered[key] !== undefined
          ) {
            sign += ordered[key];
          }
        }
      });
      // console.log(sign + APPKEY);
      sign = md5(sign + APPKEY);
      config.params.sign = sign;
    } else {
      sign = md5(user_info.token + APPKEY);
      config.params = {
        token: user_info.token,
        sign: sign,
      };
    }
    // console.log(config.sign);
  } else if (config.method === "post") {
    config.data.timestamp = gettime;
    config.data.is_pc = 1;
    if(!config.url.includes('api/member/set_password'))config.data.token = user_info.token;
    // config.data.face = user_info.face;
    if (config.data) {
      const ordered = {};
      //排序
      Object.keys(config.data)
        .sort()
        .forEach(function (key) {
          if (key !== "sign") {
            ordered[key] = config.data[key];
          }
        });
      //组合
      Object.keys(ordered).forEach((key) => {
        // console.log(ordered[key]);
        if (ordered[key] instanceof Array) {
          sign += JSON.stringify(ordered[key]);
        } else if (ordered[key] instanceof Object) {
          sign += JSON.stringify(ordered[key]);
        } else if (
          ordered[key] !== "" &&
          ordered[key] !== null &&
          ordered[key] !== undefined
        ) {
          // console.log(ordered[key]);
          sign += ordered[key];
        }
      });
      // console.log(sign);
      sign = md5(sign + APPKEY);
      config.data.sign = sign;
    } else {
      sign = md5(user_info.token + APPKEY);
      config.data = {
        token: user_info.token,
        sign: sign,
      };
    }
    }

  // 并发限制
  let QPS = 20;
  let OFFSET = 10;
  let times = 1000
  // console.log("url", config.url);
  if(config.url.indexOf('upload') > -1){
    QPS = 2;
  }
  if(config.url.indexOf('vipgys_upload_img') > -1){
    QPS = 1;
    times = 2000
  }
  // 引入Performance库来获取当前时间戳
  // const Performance = require('perf_hooks').performance
  // const now = Math.trunc(Performance.timeOrigin + Performance.now()) // Math.trunc(1597224439841.351)=1597224439841
  const now = new Date().getTime();
  let { count, ts } = qpsMap.get(config.url) || {
    count: 1,
    ts: now,
  };

  // console.log( "Before", config.url, now, ts, (now / 1000) >> 0 <= (ts / 1000) >> 0, count );
  // 通过位运算实现取整，提高效率
  if ((now / times) >> 0 <= (ts / times) >> 0) {
    // 如果当前时间 ≤ Map中该接口的ts时间，说明前面已经有超过并发后在等待的请求了
    // 只比较秒，忽略毫秒，因为QPS是以秒为周期计算的，即每秒多少个请求数
    if (count < QPS) {
      // 如果当前url的请求数没有达到QPS的限制，则计数器+1
      count++;
    } else {
      // 否则，重置计数器，同时将时间戳设置为当前ts的下一整秒
      // 这里需要将ts设置为当前ts的下一秒，而不是当前时间，因为当前ts可能已经远大于当前时间了
      // 这里修改以前的下取整为上取整，从而可以解决OFFSET问题
      ts = times * Math.ceil(ts / times + 1);
      count = 1;
    }
  } else {
    // 否则：当前时间大于ts，说明已经没有排队的请求了（可能有未完成的，但是都已经请求了）
    // 则将当前ts重置
    ts = now;
    count = 1;
  }
  qpsMap.set(config.url, {
    count,
    ts,
  });
  // console.log("After ", config.url, now, ts, count);

  // 计算休眠时间：
  // 由于本地服务器和远程服务器之间可能存在时间差会发生这种情况：
  // 前5个请求在10:00:00.200时发送过去后，此时本地时间可能到了10:00:00.900到来的第六请求由于超出了QPS=5的限制，会休眠100ms
  // 但是由于本地和服务端时间差的问题，第六个休眠100ms后发送了请求，服务端的时间可能才是10:00:00.950，导致了QPS超限报错
  // 所以，这里添加一个OFFSET偏移值来纠正本地和服务端之间的时间差问题，默认为0ms，若出现QPS超限，请酌情增大此值
  let sleep = ts - now;
  sleep = sleep > 0 ? sleep + OFFSET : 0;
  // console.log("Sleep Is", sleep);
  // 让当前的请求睡一会儿再请求
  await new Promise((resolve) => setTimeout(() => resolve(), sleep));
  // 原封不动返回config，或做一些你自己的处理后返回
  return config;
};
http.interceptors.request.use(qpsController, (error) => {
  console.log(error);
  return Promise.reject();
});

http.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      if (response.data.ResponseStatus === 600) {
        localStorage.removeItem("member");
        window.location.href = "/login";
      } else {
        return response.data;
      }
    } else {
      Promise.reject();
    }
  },
  (error) => {
    return null
  }
);
export default http;
