import "core-js/es";
import "mutation-observer";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ConfigProvider, message } from "antd";
import zhCN from "antd/lib/locale/zh_CN";
import "config/global";
import * as dayjs from "dayjs";
React.$dayjs = dayjs;
message.config({
  top: 300,
  duration: 2,
  maxCount: 3,
});

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <App />
  </ConfigProvider>,
  document.getElementById("root")
);
