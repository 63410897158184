// less
import "./index.less";

// module
import MainBottom from "../../components/MainBottom";

const Module10 = (props) => {
  const { datas } = props;

  const templateFn = (key) => {
    return (datas[key] || { template_common: [] })?.template_common || [];
  };
  return (
    <div className="new_home-module10">
      <div className="module10-top">
        <div className="module10-right">
          {templateFn("right")?.map?.((k, i) => (
            <div
              className="module10-right-item"
              key={i}
              onClick={() => {
                if (k.template_url) window.open(k.template_url);
              }}
            >
              <img
                src={`${k?.template_img}?x-oss-process=image/resize,m_lfit,h_300,w_300`}
                alt={k?.template_name}
              />
            </div>
          ))}
        </div>
      </div>

      {templateFn("main_bottom_left")?.length ||
      templateFn("main_bottom_right")?.length ? (
        <div className="new_home_main_bottom">
          <MainBottom data={templateFn("main_bottom_left")} />
          <MainBottom data={templateFn("main_bottom_right")} />
        </div>
      ) : null}
    </div>
  );
};
export default Module10;
