import { Image } from "antd";
import "./bottom.less";
import img1 from "../../../assets/imgs/relation6.png";
import img2 from "../../../assets/imgs/relation5.png";
import img3 from "../../../assets/imgs/relation4.png";
// const html = `<form id='alipaysubmit' name='alipaysubmit' action='https://openapi.alipay.com/gateway.do?charset=UTF-8' method='POST'><input type='hidden' name='biz_content' value='{\"pre_order_no\":\"VJI%2BMW3u3UUxI4TXNdVg9O1aKD4o8MtTW2Cx7iGetp9QuV7wZPa3diLVe3Rp8%2F7sA%2Bi8d%2FMOHsnu9DtEtf8BrRBIuMwd9TjuDcW%2BS64VMR5gVXhykJ%2Bl%2425\"}'/><input type='hidden' name='app_id' value='2021002154627140'/><input type='hidden' name='version' value='1.0'/><input type='hidden' name='format' value='json'/><input type='hidden' name='sign_type' value='RSA2'/><input type='hidden' name='method' value='alipay.trade.wap.merge.pay'/><input type='hidden' name='timestamp' value='2021-11-25 15:40:08'/><input type='hidden' name='alipay_sdk' value='alipay-sdk-PHP-4.11.14.ALL'/><input type='hidden' name='charset' value='UTF-8'/><input type='hidden' name='sign' value='JhskWkO9jCDLnV4gmkiYLm/5aQlKwwnRSffjlg32xh2Jm5w9gse+BFN72dTLursuoVA6wSZP5iyRG1iGUR/M8B+V+IMkcx1ZNx0Lw1NQtjAGMVEVW3F5p/3TJ00e/m1q52m8WsWJRo5h7w1nXWwMvaehiZ83MeJH/KcrqFePWge8X5D5rV7SqsLaf86QBHf+zATS9TyQVQVmEhMMSeYvgtVlnieuA4VhDl6t7LcttjH3eeHPn/pNnx1Whzfn8O3oX3Dpied2jeoMt4W/WazAHR76gi+5MViY1E63Zon0SiBYf6/PomDGgQslmozvogWGkX2uXWGKNuWOohBvsJSSlw=='/><input type='submit' value='ok' style='display:none;''></form><script>document.forms['alipaysubmit'].submit();</script>`;
const Bottom = (props) => {
  const { data } = props;
  const goArticle = (k) => {
    if (k.type === 1) {
      window.open(`/article?id=${k.id}`);
    } else if (k.type === 2) {
      window.open(k.content);
    }
  };

  return (
    <div className="bottom">
      <div className="container">
        {global.private ? null : (
          <div className="intro flex-b">
            <div className="erw flex">
              <div>
                <Image
                  width={150}
                  preview={false}
                  src={data.mall_wx_logo}
                ></Image>
                <p className="fw700 fz18 _666 mtop10">官方微信小程序</p>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Image
                  width={150}
                  preview={false}
                  src={data.mall_gzh_logo}
                ></Image>
                <p className="fw700 fz18 _666 mtop10">官方微信公众号</p>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <Image
                  width={150}
                  preview={false}
                  src={data.mall_app_logo}
                ></Image>
                <p className="fw700 fz18 _666 mtop10">官方App</p>
              </div>
            </div>
            <ul className="flex-b">
              {data.article_class &&
                data.article_class.map((k, i) => (
                  <li
                    className="mr20"
                    key={i}
                    style={{ display: i > 4 ? "none" : "", width: "20%" }}
                  >
                    <div className="title">{k.class_name}</div>
                    <ol className="_ccc">
                      {k.article.map((item, index) => (
                        <li
                          key={index}
                          className="hover-red pointer"
                          onClick={() => {
                            goArticle(item);
                          }}
                        >
                          {item.title}
                        </li>
                      ))}
                    </ol>
                  </li>
                ))}
            </ul>
          </div>
        )}
        <div className="introd container">
          {global.private ? null : (
            <ul className="aboutUs flex flex-centerY">
              {data.bottom_article &&
                data.bottom_article.map((k, i) => (
                  <div className="flex" key={i}>
                    {i !== 0 ? <li className="lined"></li> : null}
                    <li
                      className="hover-red pointer"
                      onClick={() => {
                        goArticle(k);
                      }}
                    >
                      {k.title}
                    </li>
                  </div>
                ))}
            </ul>
          )}
          <div className="paytype flex-centerY">
            <Image width={95} preview={false} src={img1}></Image>
            <Image width={95} preview={false} src={img2}></Image>
            <Image width={95} preview={false} src={img3}></Image>
          </div>
          <div
            className="company fz12 _999"
            dangerouslySetInnerHTML={{ __html: data.bottom }}
          />
        </div>
      </div>
    </div>
  );
};
export default Bottom;
